import React from 'react';
import { StyleSheet, View, PermissionsAndroid } from 'react-native';
import { useTheme } from '@react-navigation/native';
import {_hasLocationPermission, _requestLocationPermission} from '../utils/geolocation';
import StyledText from './StyledText';

export function Permissions(props: any): React.ReactElement {
  return (
    <View style={styles.container}>
        <StyledText>No permissions to grant.</StyledText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 20,
  },
});
