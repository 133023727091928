import { MenuItem } from './types';

export const DEFAULT_MENU_PATH = '';

export function getDataFromPath(menuItem: MenuItem, path: string) {
    const parentNames = [menuItem.props.title];
    let subData = menuItem;
    if (!path || !menuItem?.props?.submenu?.length) {
        addParentTitle(subData, parentNames);
        return {item: subData, parentNames};
    }
    const indexes = path.split(',');
    indexes.forEach((index: string) => {
        const ind = parseInt(index.trim(), 10);
        // @ts-ignore
        subData = subData.props.submenu[ind];
        parentNames.push(subData.props.title);
    });
    addParentTitle(subData, parentNames);
    return {item: subData, parentNames};
}

export function addParentTitle(item: any, names: string[]) {
    item.props.submenu.forEach((item: any) => {
        item.parentNames = names;
    });
}
