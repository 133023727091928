import { createNavigationContainerRef, StackActions } from '@react-navigation/native';
import { saveMarkFromUrl } from './marks/marks';

export const navigationRef = createNavigationContainerRef();

export function navigate(name: string, params: any) {
  if (navigationRef.isReady()) {
    // @ts-ignore
    navigationRef.navigate(name, params);
  }
}

export function push(name: string, params: any) {
  if (navigationRef.isReady()) {
    const pushAction = StackActions.push(name, params);
    navigationRef.dispatch(pushAction);
  }
}

export function handleUri(uri: string) {
  if (!navigationRef.isReady()) {return;}

  if (uri.substring(0, 3) === 'wc:') {
    // @ts-ignore
    navigationRef.navigate('WalletConnectPage', {uri});
  }
  else if (uri.includes('marks.provable.dev') || uri.includes('mark.provable.dev')) {
      saveMarkFromUrl(uri);
      // @ts-ignore
      navigationRef.navigate('MenuPage', {path: ''});
  }
  else {
    // @ts-ignore
    navigationRef.navigate('BrowserPage', {url: uri});
  }
}

export async function extension () {
  const extensions = {
      label: 'navigation',
      items: [
          {
              label: 'navigate',
              value: navigate,
          },
          {
              label: 'push',
              value: push,
          },
          {
            label: 'handleUri',
            value: handleUri,
        },
      ],
  };

  return {extensions};
}
