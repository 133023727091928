const menuJson: any = [
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'Photo Proof',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['CameraPage']},
        },
    },
];

export default menuJson;
