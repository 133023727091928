import React, {ReactElement, useState, useEffect, useCallback} from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import Menu from '../Menu';
import type { Routes } from '../../views/Routes';
import {Profile} from '../../services/wallet/types';
import {getProfiles} from '../../services/wallet/accounts';
import commonStyles from '../../styles';
import {useActions} from '../../services/plugems/plugems';
import {MenuItem} from '../../services/menus/types';

type Props = NativeStackScreenProps<Routes, 'ProfilesPage'>;
export default function ProfileList({navigation}: Props): ReactElement {
    const { colors } = useTheme();
    const actions = useActions();
    const [profilesStr, setProfiles] = useState('[]');

    useEffect(() => {
        async function init() {
            const profiles = await getProfiles();
            setProfiles(JSON.stringify(profiles));
        }
        init();
    }, []);

    React.useEffect(() => {
        if (!actions?.events?.plugems?.wallet) {return;}
        const listener = (profiles: any) => {
            setProfiles(JSON.stringify(profiles));
        };
        actions.events.plugems.wallet.onProfiles(listener);
        return () => actions.events.plugems.wallet.offProfiles(listener);
    });

    const onAddProfile = () => {
        navigation.navigate('ProfileNewPage');
    };

    const profiles: Profile[] = JSON.parse(profilesStr);
    const items = profiles.map((profile: Profile) => profileToItem(profile));
    const itemMenu: MenuItem = {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'identities',
            submenu: items,
        },
    };

    return (
        <View style={{flex: 1}}>
            <View style={styles.addButton}>
                <TouchableOpacity style={styles.button} onPress={onAddProfile}>
                    <IonIcon name="add" color={colors.text} size={24} />
                </TouchableOpacity>
            </View>
            <Menu menu={itemMenu} value={itemMenu} path={''} index={0}/>
        </View>
    );
}

function profileToItem(profile: Profile): MenuItem {
    return {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: profile.name.name,
            onPress: {type: 'function', subtype: 'navigation.navigate', noMenuNavArgs: true, args: ['ProfilePage', {value: profile}]},
        },
    };
}

const styles = StyleSheet.create({
    addButton: {
        // position: 'absolute',
        // top: 150,
        // left: 150,
        // width: 40,
        // height: 40,
    },
    button: {
        ...commonStyles.iconButton,
    },
});
