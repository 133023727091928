import {folderToExtensions, extensionToFolder} from './extension';

async function existsFile(fileName: string) {
    console.error('Filesystem not implemented');
    return false;
}

async function makeDir(path: string) {
    console.error('Filesystem not implemented')
}

async function readDir(name: string) {
    console.error('Filesystem not implemented')
    return [];
}

async function writeFile(path: string, data: any, encoding?: string) {
    console.error('Filesystem not implemented')
}

async function readFile(name: string, encoding?: string) {
    console.error('Filesystem not implemented')
    return '';
}

const RNFS = {
    DocumentDirectoryPath: 'myganas',
    exists: existsFile,
    mkdir: makeDir,
    readDir: readDir,
    writeFile: writeFile,
    readFile: readFile,
}

export const Filesystem = RNFS;

export function getRootDir(): string {
    let dirname = RNFS.DocumentDirectoryPath;
    return dirname;
}

export function getCacheDir(): string {
    return getRootDir() + '/caches';
}

export function getDirFromExtension(extension: string): string {
    return getRootDir() + '/' + extensionToFolder[extension];
}

export async function initDirs() {
    const folders = Object.keys(folderToExtensions);
    for (let folderName of folders) {
        await createDirIfNotExists(getRootDir() + '/' + folderName);
    }
    await createDirIfNotExists(getCacheDir());
}

export async function createDirIfNotExists(dirpath: string) {
    let exists = await RNFS.exists(dirpath);
    if (!exists) {
        await RNFS.mkdir(dirpath).catch(console.error);
    }
}

export async function getDocs(dirname: string): Promise<any> {
    return RNFS.readDir(dirname);
}

export async function _saveFile(filepath: string, data: any, encoding: string) {
    return RNFS.writeFile(filepath, data, encoding);
}

export async function saveFile({
    name, size, type, extension, data, encoding,
}: {name: string, size: number, type: string, extension: string, data: any, encoding: string}) {
    if (!name) {name = 'Unknown_' + (new Date().getTime());}
    if (!extension) {throw new Error('File is missing extension');}
    if (!data) {throw new Error('File is empty');}

    console.info('---saveFile--', name, extension);
    const filepath = getDirFromExtension(extension) + '/' + name + '.' + extension;
    return _saveFile(filepath, data, encoding);
}

export async function getFile(filepath: string, encoding: string) {
    return RNFS.readFile(filepath, encoding);
}

export function shareFile(...args: any) {
    // @ts-ignore
    return Share.open(...args);
}
