import { io } from 'socket.io-client';
import { IMessage } from 'react-native-gifted-chat';

interface Identity {
    id: string;
}

interface TransportMessage {
    message: IMessage;
    sender: string;
    to: string;
}

class SocketioTransport {
    config: any;
    identity: Identity;
    users: any;
    socket: any;
    topics: any;

    constructor ({config, identity}: {config: any, identity: Identity}) {
        this.config = config;
        this.identity = identity;
        this.socket = io(config.server, { autoConnect: false});
        this.topics = {};
    }

    createIdentity () {
        return { public: {}, private: {}};
    }

    async init () {
        this.socket.auth = { username: this.identity.id };

        this.socket.on('disconnect', (reason: string) => {
            console.debug('Socketio disconnect', reason); // "ping timeout"
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.socket.connect();
            }
            // else the socket will automatically try to reconnect
        });

        this.socket.on('connect', () => {
            console.info('socket connected');
        });

        await this.socket.connect();
    }

    // TODO subscribe to your contacts connecting
    async disconnect () {
        this.socket.off('connect_error');
        this.socket.off('user connected');
        this.socket.off('disconnect');
        for (let topic of Object.keys(this.topics)) {
            this.socket.off(topic);
        }
    }

    async initiateConnect(contact: Identity, topic: string, onMessage: Function) {
        console.debug('---initiateConnect with', contact);
        // TODO random
        this.socket.emit('topicset', {content: topic});

        this.socket.on(topic, (data: TransportMessage) => {
            this.onMessage(topic, data);
        });

        this.topics[topic] = {
            onMessage,
            messageMap: {},
            contact,
        };

        return topic;
    }

    async sendMessage (topic: string, message: any) {
        const tmessage: TransportMessage = {
            message,
            sender: this.identity.id,
            to: this.topics[topic].contact.id,
        };
        if (this.topics[topic].messageMap[message._id]) {return;}
        this.socket.emit(topic, tmessage);
    }

    async onMessage(topic: string, message: TransportMessage) {
        if (message.sender === this.identity.id) {
            // console.debug('Message is mine');
            return;
        }

        if (this.topics[topic].messageMap[message.message._id]) {return;}
        this.topics[topic].messageMap[message.message._id] = true;
        this.topics[topic].onMessage(message.message);
    }
}

export default SocketioTransport;
