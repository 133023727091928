import * as distribution from 'cosmjs-types/cosmos/distribution/v1beta1/query';
import * as bank from 'cosmjs-types/cosmos/bank/v1beta1/query';
import * as staking from 'cosmjs-types/cosmos/staking/v1beta1/query';
import * as slashing from 'cosmjs-types/cosmos/slashing/v1beta1/query';
import * as gov from 'cosmjs-types/cosmos/gov/v1beta1/query';
import * as params from 'cosmjs-types/cosmos/params/v1beta1/query';
import * as ibcClient from 'cosmjs-types/ibc/core/client/v1/query';
import * as ibcConnection from 'cosmjs-types/ibc/core/connection/v1/query';
import * as ibcChannel from 'cosmjs-types/ibc/core/channel/v1/query';

const allQueries: any = {
    ...distribution,
    ...bank,
    ...staking,
    ...slashing,
    ...gov,
    ...params,
    ...ibcClient,
    ...ibcConnection,
    ...ibcChannel,
};

export default allQueries;

// e.g. /cosmos.bank.v1beta1.Query/Balance
export function getQueryName(path: string) {
    const args = path.split('/');
    return args[args.length - 1];
}

// -> QueryBalanceRequest
export function getQueryMethodName(path: string) {
    const name = getQueryName(path);
    return 'Query' + name + 'Request';
}

export function getQueryRequestInstance(path: string) {
    const name = getQueryMethodName(path);
    return allQueries[name];
}

// QueryBalanceResponse
export function getQueryResponseName(path: string) {
    const name = getQueryName(path);
    return 'Query' + name + 'Response';
}

export function getQueryResponseInstance(path: string) {
    const name = getQueryResponseName(path);
    return allQueries[name];
}
