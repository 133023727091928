import eventemitter from './eventemitter';

const web3Injection = (chainId: string) => { return `
(async function() {

  const INJECTION_MSG = {
    send: 'ganas_provider_send',
    connect: 'ganas_provider_connect',
    receive: 'ganas_provider_receive',
  }

  const INJECTION_MSG_R = {}
  Object.entries(INJECTION_MSG).forEach(([key, value]) => INJECTION_MSG_R[value] = key);

  const targetOrigin = window.location.origin;

  const postMessage = (msg) => {
    // console.log('----web3inject postMessage', msg);
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }

  ${eventemitter}

  class EthereumProvider extends EventEmitter {
    constructor() {
      super();

      // Init storage
      this._nextJsonrpcId = 0;
      this._promises = {};
      this.chainId = ${chainId};
      this._isConnected = false;

      // Fire the connect
    //   this._connect();

      // Listen for jsonrpc responses
      window.addEventListener('message', this._handleJsonrpcMessage.bind(this));
    }

    isConnected() {
        return this._isConnected;
    }

    /* Methods */

    enable() {
      // Fire the connect
      this._connect();
    }

    request(obj) {
        return this.send(obj);
    }

    send({method, params = []}) {
      if (!method || typeof method !== 'string') {
        return new Error('Method is not a valid string.');
      }

      if (!(params instanceof Array)) {
        return new Error('Params is not a valid array.');
      }
      // console.log('provider send', method, params);

      const id = this._nextJsonrpcId++;
      const jsonrpc = '2.0';
      const payload = { jsonrpc, id, method, params };

      const promise = new Promise((resolve, reject) => {
        this._promises[payload.id] = { resolve, reject };
      });

      // Send jsonrpc request to Mist
      postMessage(
        { type: INJECTION_MSG.send, message: payload },
        targetOrigin
      );

      return promise;
    }

    /* Internal methods */

    _handleJsonrpcMessage(event) {
      // Return if no data to parse
      if (!event || !event.data) {
        return;
      }

      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        // Return if we can't parse a valid object
        return;
      }

      if (!INJECTION_MSG_R[data.type]) return;

      // Return if not a jsonrpc response
      if (!data || !data.message || !data.message.jsonrpc) {
        return;
      }
      // console.log('_handleJsonrpcMessage', data.type, data);
      const message = data.message;
      const { id, method, error, result } = message;

      if (typeof id !== 'undefined') {
        const promise = this._promises[id];
        if (promise) {
          // Handle pending promise
          if (data.type === 'error') {
            promise.reject(message);
          } else if (message.error) {
            promise.reject(error);
          } else {
            // console.log('---rpc answer', result);
            promise.resolve(result);
          }
          delete this._promises[id];
        }
      } else {
        if (method && method.indexOf('_subscription') > -1) {
          // Emit subscription notification
          this._emitNotification(message.params);
        }
      }
    }

    /* Connection handling */

    _connect() {
      // Send to Mist
      postMessage(
        { type: INJECTION_MSG.connect },
        targetOrigin
      );

      // Reconnect on close
      // this.once('close', this._connect.bind(this));

      this._isConnected = true; // FIXME
      this._emitConnect();
    }

    /* Events */

    _emitNotification(result) {
      this.emit('notification', result);
    }

    _emitConnect() {
      this.emit('connect', { chainId: this.chainId })
    }

    _emitClose(code, reason) {
      this.emit('close', code, reason);
    }

    _emitNetworkChanged(networkId) {
      console.log('--injected--_emitNetworkChanged-----', networkId)
      this.emit('networkChanged', networkId);
    }

    _emitAccountsChanged(accounts) {
      console.log('--injected--_emitAccountsChanged-----', accounts)
      this.emit('accountsChanged', accounts);
    }

    /* web3.js Provider Backwards Compatibility */

    sendAsync(payload, callback) {
      // console.log('provider sendAsync', payload, callback);
      return this.send(payload.method, payload.params)
        .then(result => {
          const response = payload;
          response.result = result;
          callback(null, response);
        })
        .catch(error => {
          callback(error, null);
          // eslint-disable-next-line no-console
          console.error(
            'Error from EthereumProvider sendAsync', payload, error
          );
        });
    }
  }

  const injectedProvider = new EthereumProvider();

    const web3 = {currentProvider: injectedProvider}
    window.web3 = web3;
    window.ethereum = injectedProvider;
}).call(this);
`};

export default web3Injection;
