import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import QRCode from 'react-qr-code';
import PageWrap from '../components/PageWrap';

function QrcodePage() {
  const { colors } = useTheme();
  let textStyles = { ...styles.text, color: colors.text };

  let value = 'https://marks.provable.dev/?m=e';

  return (
    <PageWrap>
      <View style={{ height: 'auto', margin: '0', maxWidth: 64, width: '100%', backgroundColor: 'white' }}>
        <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={value}
            // @ts-ignore
            viewBox={'0 0 256 256'}
        />
    </View>
    </PageWrap>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginHorizontal: 20,
    padding: 5,
  },
  containerText: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 20,
  },
  text: {
    marginBottom: 20,
  },
});

export default QrcodePage;
