import React from 'react';
import {
  View,
} from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import withObservables from '@nozbe/with-observables';
import { Q } from '@nozbe/watermelondb';
import type { Routes } from './Routes';
import Menu from '../components/Menu';
import {DEFAULT_MENU_PATH} from '../services/menus/utils';
import {getBlockTreeCollection} from '../services/blocktree/storage';
import {modelToBlock, blockToMenuItem} from '../services/blocktree/utils';
import {BlockTreeBlock} from '../components/blocktree/BlockTreeBlock';

type Props = NativeStackScreenProps<Routes, 'BlockTreeScreen'>;
export default function BlockTreeScreen({ navigation, route }: Props): React.ReactElement {
  const Component = enhance(BlockTree);
  return <Component collection={getBlockTreeCollection()} />;
}

function BlockTree({records}: any) {
  const Component = enhanceInner(BlockTreeInner);
  const comps = records.map((b: any, i: number) => {
    return (<Component key={i} record={b} />);
  });

  return (
    <View style={{flex: 1}}>
        {comps}
    </View>
  );
}

function BlockTreeInner({record}: any) {
  const block = modelToBlock(record);
  const menu = blockToMenuItem(block);

  return (
    <View style={{flex: 1}}>
        <BlockTreeBlock value={block} />
        <Menu menu={menu} path={DEFAULT_MENU_PATH} value={menu} index={0}/>
    </View>
  );
}

const enhance = withObservables(['collection'], ({ collection }) => ({
  records: collection.query(Q.where('label', 'blocks')).observe(),
}));

const enhanceInner = withObservables(['record'], ({ record }) => ({
  record: record.observe(),
}));
