import {Dimensions, Platform} from 'react-native';
import StaticSafeAreaInsets from 'react-native-static-safe-area-insets';

export const CONTENT_SPACING = 15;
export const BUTTON_SIZE = 40;

const insets = StaticSafeAreaInsets || {
  safeAreaInsetsBottom: 0,
  safeAreaInsetsLeft: 0,
  safeAreaInsetsTop: 0,
  safeAreaInsetsRight: 0,
};

const SAFE_BOTTOM =
  Platform.select({
    ios: insets.safeAreaInsetsBottom,
  }) ?? 0;

export const SAFE_AREA_PADDING = {
  paddingLeft: insets.safeAreaInsetsLeft + CONTENT_SPACING,
  paddingTop: insets.safeAreaInsetsTop + CONTENT_SPACING,
  paddingRight: insets.safeAreaInsetsRight + CONTENT_SPACING,
  paddingBottom: SAFE_BOTTOM + CONTENT_SPACING,
};

// The maximum zoom _factor_ you should be able to zoom in
export const MAX_ZOOM_FACTOR = 20;

export const SCREEN_WIDTH = Dimensions.get('window').width;
export const SCREEN_HEIGHT = Platform.select<number>({
  android:
    Dimensions.get('screen').height - insets.safeAreaInsetsBottom,
  ios: Dimensions.get('window').height,
  web: Dimensions.get('window').height,
}) as number;

// Capture Button
export const CAPTURE_BUTTON_SIZE = 78;
