import {ReactElement, useState, useEffect, useCallback} from 'react';
import {View, TextInput, Button, Text, TouchableOpacity, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import Menu from '../Menu';
import type { Routes } from '../../views/Routes';
import * as walletTypes from '../../services/wallet/types';
import * as wallet from '../../services/wallet/accounts';
import Clipboard from '@react-native-clipboard/clipboard';
import StyledText from '../StyledText';
import commonStyles from '../../styles';
import {MenuItem} from '../../services/menus/types';

type Props = NativeStackScreenProps<Routes, 'ProfilePage'>;
export default function ProfilePage({navigation, route}: Props): ReactElement {
    const { colors } = useTheme();
    const profile = route.params.value;
    const [accountsStr, setAccounts] = useState<string>(JSON.stringify(profile.accounts));
    const [importShow, setImportShow] = useState(false);


    const onImportShow = () => {
        setImportShow(true);
    };

    const onImport = useCallback((account: walletTypes.PublicAccount) => {
        if (!account) {return;}
        const accounts: walletTypes.PublicAccount[] = JSON.parse(accountsStr);
        accounts.push(account);
        setAccounts(JSON.stringify(accounts));
    }, [accountsStr]);

    const onAddAccount = useCallback(async () => {
        const account = await wallet.createAddress(profile.index);
        if (!account) {return;}
        const accounts: walletTypes.PublicAccount[] = JSON.parse(accountsStr);
        accounts.push(account);
        setAccounts(JSON.stringify(accounts));
    }, [accountsStr, profile]);

    const accounts: walletTypes.PublicAccount[] = JSON.parse(accountsStr);
    const items = accounts.map((account: any) => addressToItem(account));
    const menuItem: MenuItem = {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'identities',
            submenu: items,
        },
    };

  return (
    <View style={{flex: 1}}>
        <View style={styles.addButton}>
            <TouchableOpacity style={styles.button} onPress={onAddAccount}>
                <IonIcon name="add" color={colors.text} size={24} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={onImportShow}>
                <IonIcon name="download" color={colors.text} size={24} />
            </TouchableOpacity>
        </View>
        {importShow && (<ImportAccount colors={colors} index={profile.index} onImport={onImport} />)}
        <Menu menu={menuItem} value={menuItem} path={''} index={0}/>
    </View>
  );
}

function ImportAccount({colors, onImport, index}: {colors: any, onImport: any, index: number}) {
    const [pk, setPk] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const onChangeText = (val: string) => setPk(val);

    const onImportAccount = useCallback(async () => {
        if (!pk) {return;}
        let account;
        try {
            account = await wallet.importAccount(index, pk);
        } catch (e: any) {
            setErrorMessage(e.message);
        }
        setPk('');
        onImport(account);
    }, [pk, index, onImport]);

    return (
        <View style={styles.sectionContainer}>
            {errorMessage !== '' && (
            <View style={{flex: 1, width: '100%', display: 'flex'}}>
              <StyledText style={{color: 'rgb(255, 121, 198)', textAlign: 'center'}}>{errorMessage}</StyledText>
              <TouchableOpacity style={styles.button} onPress={() => setErrorMessage('')}>
                  <IonIcon name="close" color={colors.text} size={24} />
              </TouchableOpacity>
            </View>
            )}
            <TextInput
            style={{
                ...styles.seedInput,
                borderColor: colors.border,
                color: colors.text,
                backgroundColor: colors.input,
            }}
            multiline={true}
            numberOfLines={2}
            placeholder="privateKey"
            placeholderTextColor={colors.placeholder}
            onChangeText={onChangeText}
             />
            <Button title="Import Private Key" color={colors.primary}
            onPress={onImportAccount}
            />
        </View>
    );
}

function addressToItem(account: any): MenuItem {
    return {
        type: 'Item',
        props: {
            hasNavArrow: false,
            title: account.name + ' ' + account.address,
            onPress: () => Clipboard.setString(account.address),
        },
    };
}

const styles = StyleSheet.create({
    addButton: {
        display: 'flex',
        // position: 'absolute',
        // top: 150,
        // left: 150,
        // width: 40,
        // height: 40,
    },
    button: {
        ...commonStyles.iconButton,
    },
    sectionContainer: {
        // marginTop: 64,
        paddingHorizontal: 24,
        height: 150,
    },
    seedInput: {
        height: 40,
        borderRadius: 3,
        flex: 1,
        borderWidth: 1,
        marginRight: 8,
        paddingLeft: 8,
        textAlign: 'center',
    },
});
