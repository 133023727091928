import {
  View,
} from 'react-native';
import StyledText from '../StyledText';

export function BlockTreeBlock({value}: any) {
    if (!value) {return (<></>);}
    return (
        <View style={{}}>
            <StyledText>label: {value.label}</StyledText>
            <StyledText>height: {value.height}</StyledText>
            <StyledText>id: {value.id}</StyledText>
            <StyledText>last child: {value.lastChildId}</StyledText>
            <StyledText>hash: {value.hash}</StyledText>
        </View>
    );
}
