import {CONTENT_SPACING, BUTTON_SIZE} from './utils/Constants';

const base: any = {
  textStyle: {
    fontFamily: 'RobotoCondensed-Regular',
  },
  codeStyle: {
    fontFamily: 'RobotoMono-Regular',
  },
  roundness: {
    radius: 10,
  },
};
const _styles = {
  imageIconStyle: {
    padding: 10,
    height: 30,
    width: 30,
    // resizeMode: "stretch",
    // alignSelf: "center",
  },
  listItem: {
    padding: 5,
    borderWidth: 1,
  },
  listHeader: {
    ...base.textStyle,
    fontSize: 20,
  },
  listTitle: {
    ...base.textStyle,
    fontSize: 18,
  },
  btnStyle: {
    // flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "center",
    borderWidth: 0.5,
    height: 40,
    borderRadius: 5,
    margin: 5,
    // position: "relative",
    marginBottom: 0,
  },
  iconButton: {
    marginBottom: CONTENT_SPACING,
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    // borderRadius: BUTTON_SIZE / 2,
    borderRadius: base.roundness.radius,
    backgroundColor: 'rgba(140, 140, 140, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const styles = {...base, ..._styles};

export default styles;
