import AsyncStorage from '@react-native-async-storage/async-storage';

export async function storeData (key: string, value: any) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    try {
        // await AsyncStorage.setItem(key, value);
        let resultPromise = AsyncStorage.setItem(key, value);
        AsyncStorage.flushGetRequests();
        return await resultPromise;
    } catch (e) {
        console.error('storeData error', e);
    }
}

export async function getData (key: string): Promise<string | null> {
    let value: string | null;
    try {
        // https://github.com/react-native-async-storage/async-storage/issues/50
        // const value = await AsyncStorage.getItem(key);
        let resultPromise = AsyncStorage.getItem(key);
        AsyncStorage.flushGetRequests();
        value = await resultPromise;
    } catch (e) {
        console.error('getData error', e);
        return null;
    }
    return value;
}

export async function getDataObject (key: string): Promise<any> {
    const value = await getData(key);
    if (!value) {return null;}
    try {
        return JSON.parse(value);
    } catch (e) {}
    return null;
}

export async function removeData (key: string) {
    try {
        await AsyncStorage.removeItem(key);
    } catch (e) {
        console.error('removeValue error', e);
    }
}

async function initVariables() {
    // storeData("GOOGLE_API_KEY", "");
}

export async function extension () {
    const extensions = {
        label: 'storage',
        items: [
            {
                label: 'set',
                value: storeData,
            },
            {
                label: 'get',
                value: getData,
            },
            {
                label: 'get-object',
                value: getDataObject,
            },
            {
                label: 'remove',
                value: removeData,
            },
        ],
    };

    await initVariables();

    return {extensions};
}
