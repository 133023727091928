import * as React from 'react';
import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useTheme } from '@react-navigation/native';
import PageWrap from '../components/PageWrap';
import type { Routes } from '../views/Routes';
import Browser from '../components/Browser';
import { useActions } from '../services/plugems/plugems';
import { useCurrentProfile } from '../services/wallet/accounts';

type Props = BottomTabScreenProps<Routes, 'BrowserPage'>;
export default function BrowserScreen({ navigation, route }: Props): React.ReactElement {
  const actions = useActions();
  let {params} = route;
  if (!params) {params = {};}
  const { url } = params;
  const theme = useTheme();
  const profile = useCurrentProfile();
  let currentChainId;
  if (profile && profile.chainType) {
    currentChainId = profile.chains[profile.chainType].monochain.split(':')[1];
  }

  return (
    <PageWrap>
      {currentChainId && (
        <Browser url={url} theme={theme} actions={actions} chainId={currentChainId} />
      )}
    </PageWrap>
  );
}
