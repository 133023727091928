import SignClient from '@walletconnect/sign-client';
import {initiateRequest, getActions, handleRequest} from '../plugems/plugems';
import {MARKS} from '../marks/marks';
import {jsonrpcRequest, getProvider} from '../web3/web3provider';
import {ChainNamespaces, Profile, PublicAccount} from '../wallet/types';
import {ConnectedDapp } from '../wallet/accounts';
import * as wallet from '../wallet/accounts';
import * as storage from '../storage';
import * as CT from '../../utils/config';

const STORAGE_ROOT = 'walletconnect';

interface SessionProposalEvent {
  id: number;
  params: {
    id: number;
    expiry: number;
    relays: { protocol: string; data?: string }[];
    proposer: {
      publicKey: string;
      metadata: {
        name: string;
        description: string;
        url: string;
        icons: string[];
      };
    };
    requiredNamespaces: Record<
      string,
      {
        chains: string[];
        methods: string[];
        events: string[];
        extension?: {
          chains: string[];
          methods: string[];
          events: string[];
        }[];
      }
    >;
    pairingTopic?: string;
  };
}

interface SessionEvent {
  id: number;
  topic: string;
  params: {
    event: { name: string; data: any };
    chainId: string;
  };
}

interface SessionRequestEvent {
  id: number;
  topic: string;
  params: {
    request: { method: string; params: any };
    chainId: string;
  };
}

interface SessionPingEvent {
  id: number;
  topic: string;
}

interface SessionDeleteEvent {
  id: number;
  topic: string;
}

interface PairingPingEvent {
  id: number;
  topic: string;
}

const PLUGEMS_REQUEST = 'plugems_request';

const DEFAULT_METHODS = [
  'eth_sendTransaction',
  'eth_signTransaction',
  'eth_sign',
  'personal_sign',
  'eth_signTypedData',
  "wallet_addEthereumChain",
  "wallet_switchEthereumChain",
  PLUGEMS_REQUEST,
];
const DEFAULT_EVENTS = ['chainChanged', 'accountsChanged'];

interface Topics {
  [key: string]: boolean
}

interface SessionProposalEvents {
  [key: string]: SessionProposalEvent
}

interface SessionRequestEvents {
  [key: string]: SessionRequestEvent
}

interface SessionRequestEventsByTopic {
  [key: string]: SessionRequestEvents
}

export default class WalletConnect {
  client: any;
  topics_confirmed: Topics = {};
  session_proposals: SessionProposalEvents = {};
  session_requests: SessionRequestEventsByTopic = {};

  constructor(data: any) {
    if (!data) return;
    this.topics_confirmed = data.topics_confirmed;
    this.session_proposals = data.session_proposals;
    this.session_requests = data.session_requests;
  }

  updateCache() {
    const cache = {
      topics_confirmed: this.topics_confirmed,
      session_proposals: this.session_proposals,
      session_requests: this.session_requests,
    }
    return storage.storeData(STORAGE_ROOT, cache);
  }

  async init() {
    let client;
    try {
      client = await SignClient.init({
        projectId: CT.WC_PROJECT_ID,
        // logger: DEFAULT_LOGGER,
        relayUrl: CT.DEFAULT_RELAY_URL,
        metadata: {
          name: 'ganas',
          description: 'ganas wallet - for the decentralized citizen of the new world order',
          url: '',
          icons: ['https://raw.githubusercontent.com/ark-us/ganas-releases/main/images/logo-512.png'],
        },
      });
    } catch (e: any) {
      // TODO what to so now?
      console.error(e, 'WalletConnct SignClient.init error');
      return {error: e.message};
    }

    client.on('session_proposal', async (event: SessionProposalEvent) => {
      // Show session proposal data to the user i.e. in a modal with options to approve / reject it
      const topic = this.getProposalTopic(event);
      this.session_proposals[topic] = event;
      await this.updateCache();
      await this.onSessionProposal(event);
    });

    client.on('session_event', (event: SessionEvent) => {
      // Handle session events, such as "chainChanged", "accountsChanged", etc.
    });

    client.on('session_request', async (event: SessionRequestEvent) => {
      // Handle session method requests, such as "eth_sign", "eth_sendTransaction", etc.
      await this.setRequest(event);
      const isQuery = this.isQuery(event);
      if (isQuery) {
        this.onSessionRequestFinalize(10, {accepted: true, request: event});
        return;
      }

      this.requestConfirmation(event);
    });

    client.on('session_ping', (event: SessionPingEvent) => {
      // React to session ping event
    });

    client.on('session_delete', async (event: SessionDeleteEvent) => {
      // React to session delete event
      delete this.topics_confirmed[event.topic];
      delete this.session_proposals[event.topic];
      delete this.session_requests[event.topic];
      await this.updateCache();
      await this.disconnectTopic(event.topic, 'disconnect');
    });

    client.on('pairing_ping', (event: PairingPingEvent) => {
      // React to pairing ping event
    });

    client.on('pairing_delete', async (event: SessionDeleteEvent) => {
      // React to pairing delete event
      delete this.topics_confirmed[event.topic];
      delete this.session_proposals[event.topic];
      delete this.session_requests[event.topic];
      await this.updateCache();
      await this.disconnectTopic(event.topic, 'disconnect');
    });

    this.client = client;

  }

  getProposalTopic (event: SessionProposalEvent) {
    return event.params.pairingTopic || event.params.proposer.publicKey;
  }

  isQuery (event: SessionRequestEvent) {
    if (!event.params || !event.params.request || !event.params.request.method || !event.params.request.params) {
      return false;
    }
    const {method} = event.params.request;
    if (!event.params.request.params || !event.params.request.params.topic) {
      return false;
    }
    const submethodName = event.params.request.params.topic.toLowerCase();

    if (method === PLUGEMS_REQUEST) {
      if (submethodName.includes('query')) return true;
      if (submethodName.includes('getaccounts')) return true;
      if (submethodName.includes('cache')) return true;
      if (submethodName.includes('encode')) return true;
      if (submethodName.includes('decode')) return true;
      if (submethodName === 'cosmos.request') {
        const args = event.params.request.params.args;
        if (!args || !args.length) return false;
        if (args[0].typeUrl && args[0].typeUrl.includes('Query')) return true;
      }
    }
    return false;
  }

  async pair (uri: string) {
    // This will trigger the `session_proposal` event
    await this.client.pair({ uri });
  }

  async disconnectTopic(topic: string, reason: string) {
    const disconnectParams = {topic, reason};
    await this.client.disconnect(disconnectParams).catch((e: Error) => {
      console.error('disconnect error', e);
    });
  }

  // this does not work in walletconnect
  // chainId - e.g. eip155:5
  async emitChainChangedEvent(chainId: string) {
    if (typeof this.client === 'undefined') {return;}
    const event = { name: 'chainChanged', data: chainId };
    for (let topic of Object.keys(this.topics_confirmed)) {
      await this.client.emit({ topic, event, chainId }).catch(console.error);
    }
  }

  // chainId - e.g. eip155:5
  async updateSessionChainChange(chainId: string) {
    if (typeof this.client === 'undefined') {return;}

    const currentProfileIndex = await getActions().wallet.getCurrentProfileIndex();
    const curentProfile: Profile = await getActions().wallet.getProfile(currentProfileIndex);
    const accounts = curentProfile.accounts.map((account: PublicAccount) => account.address);

    const parts = chainId.split(':');
    const dappChains: ChainNamespaces = {
      eip155: [],
      cosmos: [],
      monochain: curentProfile.chains[curentProfile.chainType].monochain,
      [parts[0]]: [parts[1]],
    };
    const namespaces = this.buildNamespaces(dappChains, accounts);

    for (let topic of Object.keys(this.topics_confirmed)) {
      await this.client.update({topic, namespaces}).catch(console.error);
      // await this.client.emit({ topic, event, chainId }).catch(console.error);
    }
  }
//  '---buildNamespaces--', { cosmos: [], eip155: [ '5' ], monochain: 'eip155:5' }, []
  buildNamespaces(selectedNamespaces: ChainNamespaces, selectedAcounts: string[]) {
    const namespaces: any = {};
    const _selectedNamespaces: any = {...selectedNamespaces};
    delete _selectedNamespaces.monochain;

    Object.keys(_selectedNamespaces).map((namespace: string) => {
      const chains = _selectedNamespaces[namespace].map((chain: string) => `${namespace}:${chain}`);
      if (chains.length === 0) {return;}

      namespaces[namespace] = {
        chains,
        methods: DEFAULT_METHODS,
        events: DEFAULT_EVENTS,
        accounts: buildAccounts(selectedAcounts, chains),
      };
    });
    return namespaces;
  }

  async onSessionProposal(event: SessionProposalEvent) {
    return initiateRequest((requestId: number) => {
      const requestString = encodeURIComponent(JSON.stringify(event));
      const query = `&topic=walletconnect.session-confirm&id=${requestId}&request=${requestString}`;
      const url =  MARKS.walletconnectRequestAccept + query;

      return ({
        topic: 'navigation.navigate',
        args: ['BrowserPage', { url }],
      });
    });
  }

  async onSessionConfirm(_plugemsRequestId: number, {accepted, request}: {accepted: boolean, request: SessionProposalEvent}) {
    const topic = this.getProposalTopic(request);
    if (!this.session_proposals[topic]) {
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }
    const proposalRequest = this.session_proposals[topic];
    // TODO handle by id;
    if (!accepted) {
      // Or reject session proposal
      await this.client.reject({
        id: proposalRequest.id,
        reason: {
          code: 1,
          message: 'rejected',
        },
      });
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }

    const rns = proposalRequest.params.requiredNamespaces;
    let chainNotAllowed: string | null = null;
    let areChainsRequested: boolean = false;

    const actions = getActions();
    if (!actions?.wallet) {return;}

    const currentProfileIndex = await actions.wallet.getCurrentProfileIndex();
    if (currentProfileIndex === null) {
      await this.client.reject({
        id: proposalRequest.id,
        reason: {
          code: 1,
          message: 'Ganas wallet does not have a chosen identity',
        },
      }).catch(console.error);
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }

    const curentProfile: Profile = await getActions().wallet.getProfile(currentProfileIndex);
    const chainType = curentProfile.chainType;
    let chainsRequested: ChainNamespaces = {eip155: [], cosmos: [], monochain: curentProfile.chains[chainType].monochain};

    Object.keys(rns).map((ns: string) => {
      if (rns[ns].chains.length > 0) {areChainsRequested = true;}
      // @ts-ignore
      chainsRequested[ns] = [];
      rns[ns].chains.map((chainStr: string) => {
        const chainId = chainStr.split(':')[1];
        // @ts-ignore
        let chainAllowed = curentProfile.chains[ns] && curentProfile.chains[chainType][ns].includes(chainId);
        if (!chainAllowed) {
          chainNotAllowed = chainId;
          return;
        }
        // @ts-ignore
        chainsRequested[ns].push(chainId);
      });
      if (chainNotAllowed !== null) {return;}
    });

    if (chainNotAllowed !== null) {
      await this.client.reject({
        id: proposalRequest.id,
        reason: {
          code: 1,
          message: 'Ganas wallet does not allow ' + chainNotAllowed,
        },
      }).catch(console.error);
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }

    const accounts = curentProfile.accounts.map((account: PublicAccount) => account.address);

    let dappChains: ChainNamespaces = chainsRequested;
    if (!areChainsRequested && curentProfile.chains[chainType].monochain) {
      const parts = curentProfile.chains[chainType].monochain.split(':');
      dappChains = {
        cosmos: [],
        eip155: [],
        monochain: curentProfile.chains[chainType].monochain,
        [parts[0]]: [parts[1]]
      };
    }


    const dappData: ConnectedDapp =  {
      // chains: curentProfile.chains,
      chains: dappChains,
      profileIndex: curentProfile.index,
      dapp: proposalRequest.params.proposer.metadata,
    };
    await wallet.addConnectedSite(dappData);

    return this.pairFinalize(topic, dappChains, accounts);
  }

  async pairFinalize(topic: string, selectedNamespaces: any, selectedAcounts: string[]) {
    const sessionProposal = this.session_proposals[topic];
    if (!sessionProposal) {
      // go to a default page
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }
    const proposalApproval: any = {
      id: sessionProposal.id,
      namespaces: this.buildNamespaces(selectedNamespaces, selectedAcounts),
    };

    // Approve session proposal, use id from session proposal event and respond with namespace(s) that satisfy dapps request and contain approved accounts
    const approvalPromise = this.client.approve(proposalApproval);

    // TODO each chain change is sent to the dapps
    // later, with multichain, this should not be propagated unless profile changes
    const listener = (profile: Profile) => {
      if (!profile.chains[profile.chainType].monochain) {return;}
      this.updateSessionChainChange(profile.chains[profile.chainType].monochain);
    };
    await getActions().events.plugems.wallet.onProfileCurrent(listener);

    // go to a default page
    initiateRequest((requestId: number) => {
      return ({
        topic: 'navigation.navigate',
        args: ['MenuPage', {path: ''}],
      });
    });

    let acknowledgedPromise: any;
    try {
      const { topic, acknowledged } = await approvalPromise;
      this.topics_confirmed[topic] = true;
      await this.updateCache();
      acknowledgedPromise = acknowledged;
    } catch (e) {
      console.error(e);
      return;
    }

    try {
      // Optionally await acknowledgement from dapp
      const session = await acknowledgedPromise();
    } catch (e) {
      console.error(e);
    }
  }

  sanitizeConfirmationRequest(event: SessionRequestEvent) {
    function sanitizeRequestParams(params: any) {
      const pp: any = {};
      Object.keys(params).forEach((key: any) => {
        let value = params[key];
        const _str = JSON.stringify(params[key]);
        if (_str.length > 1000) {
          value = `[Message too long. truncated:]
${_str.slice(0, 1000)}
`
        }
        pp[key] = value;
      });
      return pp;
    }
    const _event = {
      ...event,
      params: {
        ...event.params,
        request: {
          ...event.params.request,
          params: sanitizeRequestParams(event.params.request.params),
        }
      }
    }
    return _event;
  }

  requestConfirmation(event: SessionRequestEvent) {
    return initiateRequest((requestId: number) => {
      const _event = this.sanitizeConfirmationRequest(event);
      const requestString = encodeURIComponent(JSON.stringify(_event));
      const query = `&topic=walletconnect.request-finalize&id=${requestId}&request=${requestString}`;
      const url =  MARKS.walletconnectRequestAccept + query;

      return ({
        topic: 'navigation.navigate',
        args: ['BrowserPage', { url }],
      });
    });
  }

  getRequest(topic: string, id: string | number) {
    if (!this.session_requests[topic]) return;
    return this.session_requests[topic][id.toString()];
  }

  async setRequest(req: SessionRequestEvent) {
    const id = req.id.toString();
    if (!this.session_requests[req.topic]) {
      this.session_requests[req.topic] = {};
    }
    this.session_requests[req.topic][id] = req;
    await this.updateCache();
  }

  async onSessionRequestFinalize(_plugemsRequestId: number, {accepted, request: origReq}: {accepted: boolean, request: SessionRequestEvent}) {
    if (!origReq || !origReq.id || !origReq.topic) {
      console.error('WalletConnect Request cannot be finalized', accepted, origReq);
      return;
    };

    const sessionRequest = this.getRequest(origReq.topic, origReq.id);

    if (!sessionRequest) {
      console.error('WalletConnect Request cannot be finalized', accepted, origReq, this.session_requests[origReq.topic]);
      return;
    }

    const sessionProposal = this.session_proposals[origReq.topic];
    const currentProfileIndex = await getActions().wallet.getCurrentProfileIndex();
    if (!accepted || currentProfileIndex === null) {
      if (sessionProposal) {
        await this.client.reject({
          id: sessionProposal.id,
          reason: {
            code: 1,
            message: 'Rejected ' + accepted ? 'by user' : 'non-existent profile',
          },
        }).catch(console.error);
      }
      initiateRequest((requestId: number) => {
        return ({
          topic: 'navigation.navigate',
          args: ['MenuPage', {path: ''}],
        });
      });
      return;
    }

    const {request} = sessionRequest.params;

    // response = {id, result, error}
    // id must be WC request id
    let response: any;
    if (request.method === 'plugems_request') {
      const {result, error} = await this.tryHandlePlugemsRequest(request.params);
      response = {
        jsonrpc: '2.0',
        id: sessionRequest.id,
        method: request.method,
        result,
        error,
      };
    }
    else {
      // if signing/sending a tx, make sure it is with one of the allowed addresses
      // from the connected profile
      // TODO profileIndex
      const provider = await getProvider(sessionRequest.params.chainId);
      response = await jsonrpcRequest(
        {message: {...request, id: sessionRequest.id}},
        currentProfileIndex,
        provider,
      );
    }

    await this.client.respond({
      topic: sessionRequest.topic,
      response,
    }).catch((e: Error) => {
      console.error(e, sessionRequest.topic, response);
    });

    initiateRequest((requestId: number) => {
      return ({
        topic: 'navigation.navigate',
        args: ['MenuPage', {path: ''}],
      });
    });
  }

  async tryHandlePlugemsRequest({topic, args}: any) {
    if (!topic) {return {result: null, error: new Error("Plugems method invalid")};}
    const _topic = topic.replace(/_/g, '.');
    return handleRequest(_topic, args);
  }
}

let connector: any;
export async function extension () {
  async function init() {
    if (!connector) {
      const cache = await storage.getDataObject(STORAGE_ROOT);
      connector = new WalletConnect(cache);
      await connector.init();
    }
  }

  const extensions = {
      label: 'walletconnect',
      items: [
          {
              label: 'init',
              value: async (...args: any) => {
                await init();
                return connector.init(...args);
              },
          },
          {
              label: 'pair',
              value: async (...args: any) => {
                await init();
                return connector.pair(...args);
              },
          },
          {
            label: 'session-confirm',
            value: async (...args: any) => {
              await init();
              return connector.onSessionConfirm(...args);
            },
          },
          {
            label: 'request-finalize',
            value: async (...args: any) => {
              await init();
              return connector.onSessionRequestFinalize(...args);
            },
          },
      ],
  };

  return {extensions};
}

function buildAccounts(accounts: string[], chainIds: string[]): string[] {
  const acc: string[] = [];
  chainIds.map((chainId: string) => {
    accounts.map((account: string) => {
      acc.push(chainId + ':' + account);
    });
  });
  return acc;
}
