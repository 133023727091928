import * as React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import StaticSafeAreaInsets from 'react-native-static-safe-area-insets';
import { useTheme } from '@react-navigation/native';

export default function PageWrap(props: any): React.ReactElement {
    const {children, style} = props;
    const { colors } = useTheme();
    const rootStyles = {
      ...styles.root,
      backgroundColor: 'transparent', // colors.background
    };
    return (
        <View style={[rootStyles, style]}>
            <View style={styles.container}>
                {children}
            </View>
        </View>
  );
}

const styles = StyleSheet.create({
    root: {
      flex: 1,
    },
    container: {
      flex: 1,
      marginTop: Platform.OS === 'ios' ? (StaticSafeAreaInsets.safeAreaInsetsTop) : 0,
      backgroundColor: 'black',
    },
});
