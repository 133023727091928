import {getBlockTreeCollection, setBlock} from './storage';
import getDatabase from '../database/index';
import {initBlocktreeData, addBlock} from './utils';

let dispatcher;
const database = getDatabase();
const blocktreedb = database.get('blocktree');

async function init() {
    const chatGroup = {id: '0x0c', participants: ['0x00', '0x01'], name: 'blue serious paasssiflora'};
    const chatMessage = {id: 1, text: 'Hello my old friend', user: '0x01'};
    const chatMessageReference = {
        // id: "0xeeee",
        hash: '0x1111',
        type: 'messages',
    };
    const chatMessageReference2 = {
        // id: "0xeeee",
        hash: '0x1112',
        type: 'messages',
    };

    const blocks = await database.write(async () => {
        await setBlock(initBlocktreeData[0]);
    });

    const newvalue = await database.write(async () => {
        const blocks = await blocktreedb.query().fetch();
        await addBlock(['chats', 'blue serious test', 'user1', '1', 'Hello..friend'], chatMessageReference, blocks[0]);
    });
    return newvalue;
}

export async function addBlockWrite(path: string[], data: any, parentRecord: any) {
    const newrecord = await database.write(async () => {
        const newrecord =  await addBlock(path, data, parentRecord);
        return newrecord;
    });
    return newrecord;
}

export async function dbWrite(operations: any) {
    return database.write(async () => {
        for (let operation of operations) {
            await operation;
        }
    });
}

let rootRecord: any;

export async function extension (_dispatcher: Function) {
    dispatcher = _dispatcher('blocktree');

    let count = await getBlockTreeCollection().query().fetchCount();
    if (count === 0) {
        await init();
    }
    rootRecord = (await blocktreedb.query().fetch())[0];

    const extensions = {
        label: 'blocktree',
        items: [
            {
                label: 'build',
                value: (records: any) => {
                    return records;
                },
            },
            {
                label: 'root',
                value: () => rootRecord,
            },
        ],
        events: [
        ],
    };

    return {extensions};
}

export function getRootRecord() {
    return rootRecord;
}
