import {ethers} from 'ethers';
import { Q, Model } from '@nozbe/watermelondb';
import {MenuItem} from '../menus/types';
import {getBlockTreeCollection, setBlock} from './storage';
import {Block, BlockData} from './types';
import {BlockTree} from '../database/Block';

export function modelToBlock(record: BlockTree): Block {
    return {
        id: record._raw.id,
        label: record.label,
        parentId: record.parentId,
        predecessorId: record.predecessorId,
        children: JSON.parse(record.children),
        lastChildId: record.lastChildId,
        hash: record.hash,
        height: record.height,
        type: record.type,
        contentId: record.contentId,
    };
}

export function blockToMenuItem(block: Block): MenuItem {
    const isLeaf = block.contentId;
    return {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: block.label,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['BlockTreePage', {}]},
            submenu: [],
        },
        observeDescendants: () => getBlockTreeCollection().query(Q.where('parent_id', block.id)).observe(),
        observeDescendantsCount: () => getBlockTreeCollection().query(Q.where('parent_id', block.id)).observeCount(),
        parseItem: (record: any) => blockToMenuItem(modelToBlock(record)),
        _value: block,
        submenuPress: isLeaf ? ({type: 'function', subtype: 'navigation.push', args: ['ChatPageByHash', {hash: block.contentId, type: block.type}]}) : undefined,
    };
}


export const weekdayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
export const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export const units = {
    time: ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'],
    location: ['country', 'state', 'city', 'street', 'number'],
    chat: ['groupidentity', 'identity', 'page', 'message'],
};

const EMPTY_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000';

export const initBlocktreeData = [
    {label: 'blocks', parentId: '', predecessorId: '', children: {}, height: 1, hash: '0x0000000000000000000000000000000000000000000000000000000000000000', type: undefined, contentId: undefined},
    {label: 'time', parentId: '', predecessorId: '', children: {}, height: 1, hash: '0x0000000000000000000000000000000000000000000000000000000000000000', type: undefined, contentId: undefined},
    {label: 'location', parentId: '', predecessorId: '', children: {}, height: 1, hash: '0x0000000000000000000000000000000000000000000000000000000000000000', type: undefined, contentId: undefined},
    {label: 'chats', parentId: '', predecessorId: '', children: {}, height: 1, hash: '0x0000000000000000000000000000000000000000000000000000000000000000', type: undefined, contentId: undefined},
];

const blocktree = getBlockTreeCollection();

export function getHash(hash1: string, hash2: string): string {
    return ethers.utils.solidityKeccak256(
        ['string', 'string'],
        [hash1, hash2],
    );
}

export function computeRootHash(data: any): string {
    return ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(
        ['tuple(string label, string parentId, string predecessorId, uint256 height, string type, string contentId) d'],
        [{
            ...data,
            type: data.type || '',
            contentId: data.contentId || '',
        }],
    ));
}

export async function addBlockMiddle(name: string, parentRecord: any) {
    let records = await blocktree.query(
        Q.where('parent_id', parentRecord._raw.id),
        Q.where('label', name),
    ).fetch();

    if (records.length > 0) {return;}
    const _record: BlockData = {
        label: name,
        parentId: parentRecord.id,
        predecessorId: parentRecord.lastChildId,
        children: {},
        hash: EMPTY_HASH,
        height: 1,
    };
    const record = await setBlock(_record);
    const children = JSON.parse(parentRecord.children);
    children[name] = record.id;

    // TODO pass leaf hash and add it to the parent
    const leafHash = '0x01';
    const parentHash = getHash(parentRecord.hash, leafHash);

    await parentRecord.update((value: any) => {
        // @ts-ignore
        value.children = JSON.stringify(children);
        // @ts-ignore
        value.lastChildId = record.id;
        value.hash = parentHash;
    });
    return record;
}

export async function addBlockLeaf(name: string, data: any, parentRecord: any) {
    let predecessor = parentRecord.lastChildId
        ? (await blocktree.query(Q.where('id', parentRecord.lastChildId)).fetch())[0]
        : null;

    // @ts-ignore
    const height = predecessor ? (predecessor.height + 1) : 1;
    const recordData = {
        label: name,
        parentId: parentRecord._raw.id,
        predecessorId: parentRecord.lastChildId,
        children: null,
        height,
        type: data.type,
        contentId: data.hash,
    };
    // @ts-ignore
    const hash = getHash(predecessor ? predecessor.hash : EMPTY_HASH, computeRootHash(recordData));
    const _record = {
        ...recordData,
        hash,
    };

    const record = await setBlock(_record);
    const children = JSON.parse(parentRecord.children);
    children[name] = record.id;
    await parentRecord.update((value: any) => {
        // @ts-ignore
        value.children = JSON.stringify(children);
        // @ts-ignore
        value.lastChildId = record.id;
    });
    return record;
}

export async function addBlock(path: string[], data: any, parentRecord: any): Promise<Model> {
    let table: any = path.shift();
    // blocktree.
    let records = await blocktree.query(
        Q.where('parent_id', parentRecord._raw.id),
        Q.where('label', table),
    ).fetch();
    let record;

    if (!records.length) {
        // if it does not not exist
        // and it is not a leaf
        if (path.length > 0 ) {
            record = await addBlockMiddle(table, parentRecord);
        }
        else {
            // is leaf
            record = await addBlockLeaf(table, data, parentRecord);
        }
    }
    else {record = records[0];}
    // if (path.length > 0) {
    //     const kid = path[0];
    //     // @ts-ignore
    //     const children = JSON.parse(record.children);
    //     if (!children[kid]) {
    //         // insert child genesis
    //         const block0 = {
    //             label: kid,
    //             parentId: record.id,
    //             predecessorId: 0,
    //             children: '{}',
    //             hash: '0x000',
    //             height: 1,
    //         }
    //         // write
    //         // blocktree.insert(block0)

    //         // TODO update record with new children
    //     }
    // }
    // @ts-ignore
    // const hash = getHash(record.hash, data.hash);
    // update record with new hash
    if (path.length === 0) {return record;}
    return addBlock(path, data, record);
}

// predecessorId sets initial hash to predecessor hash
