import React from 'react';
import {
  Text,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import styles from '../styles';

const StyledText = (props: any = {}) => {
    const { colors } = useTheme();
    let textStyles = props.style || {};
    const _styles: any = {
        ...styles.textStyle,
        color: colors.text,
    };

    return (
        <Text {...props} style={[_styles, textStyles]} />
    );
};

export default StyledText;
