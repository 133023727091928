import { useEffect, useState } from 'react';
import { generateMnemonic as _generateMnemonic, mnemonicToSeed } from 'bip39';
import { hdkey } from 'ethereumjs-wallet';
import { Wallet } from '@ethersproject/wallet';
import { Transaction } from '@ethereumjs/tx';
import {generateName} from '@ark-us/name';
import {Common} from '@ethereumjs/common';
import * as Keychain from './keychain';
import * as types from './types';

import * as storage from '../storage';
import * as CT from './constants';

let dispatcher: Function;

export async function getProfiles(): Promise<types.Profile[]> {
    const profiles = await storage.getDataObject(CT.PROFILES_STORAGE_ROOT);
    return profiles || [];
}

export async function getProfile(index: number) {
    const profiles = await getProfiles();
    index = Math.min(profiles.length, index);
    const profile = profiles[index];
    const profileChain = profile.chains[profile.chainType];
    if (!profileChain.monochain) {
        if (profileChain.eip155.length > 0) {
            profiles[index].chains[profile.chainType].monochain = 'eip155:' + profileChain.eip155[0];
        }
        else if (profileChain.cosmos.length > 0) {
            profiles[index].chains[profile.chainType].monochain = 'cosmos:' + profileChain.cosmos[0];
        }
    }
    return profiles[index];
}

export async function setProfiles(profiles: types.Profile[]) {
    await storage.storeData(CT.PROFILES_STORAGE_ROOT, JSON.stringify(profiles));
    dispatcher && dispatcher('Profiles')(profiles);
}

export async function addProfile(profile: types.Profile) {
    const profiles = await getProfiles();
    // If same seed, the first address mult be the same
    profiles.push(profile);
    await setProfiles(profiles);
    dispatcher && dispatcher('Profiles')(profiles);
    dispatcher && dispatcher('Profile')(profile);
}

export async function getCurrentProfile() {
    const profiles = await getProfiles();
    let indx = await getCurrentProfileIndex();
    if (indx == null) indx = 0;
    return profiles[indx];
}

export async function getCurrentProfileIndex() {
    const index = await storage.getData(CT.PROFILES_STORAGE_CURRENT);
    if (index != null) {return parseInt(index);}
    return null;
}

export async function setCurrentProfileIndex(index: number) {
    await storage.storeData(CT.PROFILES_STORAGE_CURRENT, index.toString());
    dispatcher && dispatcher('ProfileCurrentIndex')(index);
}

export async function setCurrentProfile(
    {index, chains, chainType}:
    {index: number, chains: types.ChainNamespaces, chainType: string},
) {
    console.log('setCurrentProfile', {index, chains, chainType});
    await setCurrentProfileIndex(index);
    const profiles = await getProfiles();
    profiles[index].chains[chainType] = chains;
    // @ts-ignore
    profiles[index].chainType = chainType;
    dispatcher && dispatcher('ProfileCurrent')(profiles[index]);
    await setProfiles(profiles);
}

export async function getAccounts(profileIndex: number) {
    const profile = await getProfile(profileIndex);
    return profile.accounts.map((account: types.PublicAccount) => account.address);
}

export async function generateProfile(mnemonic: string) {
    const _account = await deriveAccountFromMnemonic({mnemonic, index: 0});
    const profiles = await getProfiles();

    // If same seed, the first address mult be the same
    const existentIndex = profiles.findIndex((profile: types.Profile) => _account.address === profile.accounts[0].address);
    if (existentIndex > -1) {throw new Error(`Wallet is already part of a profile: ${profiles[existentIndex].name}`);}

    const index = await importWallet(mnemonic);
    const account = await generateAddress(index, mnemonic, 'root');
    await increaseWalletDerivationCount(index);

    const profile: types.Profile = {
        name: generateName(account.address),
        index,
        accounts: [account],
        chains: CT.DEFAULT_PROFILE_CHAINS,
        chainType: CT.DEFAULT_CHAIN_TYPE,
    };
    profiles.push(profile);
    await setProfiles(profiles);
    return profile;
}

export async function getProfilesCount() {
    let count = await storage.getData(CT.PROFILES_STORAGE_COUNT);
    if (!count) {return 0;}
    let walletCount: types.ProfilesCount;
    try {
        walletCount = JSON.parse(count);
    } catch (e) {
        return 0;
    }
    return walletCount.count;
}

async function increaseProfilesCount() {
    const count = await getProfilesCount();
    let walletCount = JSON.stringify({count: count + 1});
    return storage.storeData(CT.PROFILES_STORAGE_COUNT, walletCount);
}

export async function getWalletDerivationCount(profileIndex: number): Promise<number> {
    let count = await storage.getData(CT.SEEDS_STORAGE_COUNT + profileIndex);
    if (!count) {return 0;}
    let walletCount: types.WalletCount;
    try {
        walletCount = JSON.parse(count);
    } catch (e) {
        return 0;
    }
    return walletCount.count;
}

async function increaseWalletDerivationCount(profileIndex: number) {
    const count = await getWalletDerivationCount(profileIndex);
    let walletCount = JSON.stringify({count: count + 1});
    return storage.storeData(CT.SEEDS_STORAGE_COUNT + profileIndex, walletCount);
}

export async function importWallet(seed: string) {
    if (!seed) {throw new Error('Empty mnemonic provided');}
    let index = await getProfilesCount();

    const key = `${CT.SEEDS_STORAGE_ROOT}_${index}`;
    const result = await Keychain.setGenericPassword(key, seed, {
        service: key,
        accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
        accessible: Keychain.ACCESSIBLE.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
        securityLevel: Keychain.SECURITY_LEVEL.SECURE_HARDWARE,
        authenticationType: Keychain.AUTHENTICATION_TYPE.BIOMETRICS,
    });

    await increaseProfilesCount();
    return index;
}

export function generateMnemonic() {
    return _generateMnemonic();
}

function deleteSeed(message: string) {

}

function showSeed(rootAddress: string, reason: string) {
    // getGenericPassword([{ authenticationPrompt, service, accessControl }])
}

const seedCache: any = {};
export async function getProfileWallet(profileIndex: number, title: string, forceAsk = true) {
    const key = `${CT.SEEDS_STORAGE_ROOT}_${profileIndex}`;
    let password: string;
    if (forceAsk || !seedCache[key]) {
        const seed = await Keychain.getGenericPassword({
            service: key,
            authenticationPrompt: {title, description: title},
            accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
        });
        if (!seed) {return null;}
        password = seed.password;
        seedCache[key] = password;
    }
    else {
        password = seedCache[key];
    }
    // { username, password, service, storage }

    const account: any = {mnemonic: password};
    account.getAccount = (index: number) => deriveAccountFromMnemonic({mnemonic: password, index});
    return account;
}

export async function getWalletByPrivateKey(pk: string) {
    const account: any = {};
    account.getAccount = (index?: number) => deriveAccountFromPrivateKey(pk);
    return account;
}

export async function getAccountByAddress(profileIndex: number, address: string) {
    const profile = await getProfile(profileIndex);
    return profile.accounts.find((account: types.PublicAccount) => account.address === address);
}

async function addAccountToProfile(profileIndex: number, account: types.PublicAccount) {
    const profiles = await getProfiles();
    profiles[profileIndex].accounts.push(account);
    await setProfiles(profiles);
    dispatcher && dispatcher('Accounts')(profileIndex, profiles[profileIndex].accounts);
    dispatcher && dispatcher('Account')(profileIndex, account);
}

export async function createAddress(profileIndex: number, name?: string) {
    const key = `${CT.SEEDS_STORAGE_ROOT}_${profileIndex}`;
    const seed = await Keychain.getGenericPassword({
        service: key,
        authenticationPrompt: {title: 'Authenticate to create a new account'},
        accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
    });
    if (!seed) {return null;}
    const pubaccount = await generateAndAddAddress(profileIndex, seed.password, name);
    return pubaccount;
}

async function generateAndAddAddress(walletIndex: number, seed: string, name?: string): Promise<types.PublicAccount> {
    const pubaccount = await generateAddress(walletIndex, seed, name);
    await addAccountToProfile(walletIndex, pubaccount);
    await increaseWalletDerivationCount(walletIndex);
    return pubaccount;
}

async function generateAddress(walletIndex: number, seed: string, name?: string): Promise<types.PublicAccount> {
    const index = await getWalletDerivationCount(walletIndex);
    const account = await deriveAccountFromMnemonic({mnemonic: seed, index});
    const pubaccount: types.PublicAccount = {
        name: name || ('account' + index.toString().padStart(2, '0')),
        address: account.address,
        derivationIndex: index,
        external: false,
    };
    return pubaccount;
}

function privateKeyForPath(rootAddress: string, derivationPath: string, prompt: string) {

}

export async function deriveAccountFromMnemonic (
    {mnemonic, index = 0, derivationPath = CT.DEFAULT_HD_PATH}:
    {mnemonic: string, index: number, derivationPath?: string},
) {
    let seed = await mnemonicToSeed(mnemonic);
    const hdWallet = hdkey.fromMasterSeed(seed);
    const root = hdWallet.derivePath(derivationPath);
    const child = root.deriveChild(index);
    const wallet = child.getWallet();
    return {
      address: '0x' + wallet.getAddress().toString('hex'),
      privateKey: '0x' + wallet.getPrivateKey().toString('hex'),
      isHDWallet: true,
      root,
      type: 'mnemonic',
      wallet,
      walletType: 'bip39',
    };
}

export async function deriveAccountFromPrivateKey (privateKey: string) {
    const wallet = new Wallet(privateKey);
    return {
        address: wallet.address,
        privateKey: '0x' + privateKey,
        isHDWallet: false,
        type: 'privateKey',
        wallet,
        walletType: 'bip39',
      };
}

export async function importAccount(walletIndex: number, privateKey: string, name?: string) {
    const account = await deriveAccountFromPrivateKey(privateKey);

    const existingAccount = await getAccountByAddress(walletIndex, account.address);
    if (existingAccount) {throw new Error('Account already exists');}

    // Store privateKey in keychain
    const key = `${CT.PK_STORAGE_ROOT}_${account.address}`;
    const result = await Keychain.setGenericPassword(key, privateKey, {
        service: key,
        accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
        accessible: Keychain.ACCESSIBLE.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
        securityLevel: Keychain.SECURITY_LEVEL.SECURE_HARDWARE,
        authenticationType: Keychain.AUTHENTICATION_TYPE.BIOMETRICS,
    });

    const publicAccount = {name, address: account.address, external: true};
    await addAccountToProfile(walletIndex, publicAccount);
    return publicAccount;
}

async function getWalletByAddress(profileIndex: number, address: string, title: string, forceAsk = true) {
    const account = await getAccountByAddress(profileIndex, address);
    if (!account) return null;
    if (account.external) {
        const key = `${CT.PK_STORAGE_ROOT}_${address}`;
        const result = await Keychain.getGenericPassword({
            service: key,
            authenticationPrompt: {title, description: title},
            accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
        });
        if (!result) {return null;}
        return getWalletByPrivateKey(result.password);
    }
    return getProfileWallet(profileIndex, title, forceAsk);
}

export async function signTransaction(provider: any, initialTx: any, profileIndex: number) {
    if (initialTx instanceof Array) {initialTx = initialTx[0];}
    const address = initialTx.from;
    if (!address) {return;}
    // TODO find account by address
    const _wallet = await getWalletByAddress(profileIndex, address, 'Sign Transaction');
    const account = await _wallet.getAccount(0);
    const nonce = await provider.getTransactionCount(account.address);
    const txObject = {
        nonce: '0x' + nonce.toString(16),
        gasPrice: '0x' + parseInt(initialTx.gasPrice).toString(16) || '0x12a05f200',
        gasLimit: '0x' + parseInt(initialTx.gas || initialTx.gasLimit).toString(16) || '0x5b8d80',
        value: parseInt(initialTx.value) || 0,
        // ~~~TODO - global state
        // chainId: 3,
        data: initialTx.data || '0x',
        to: initialTx.to,
    };

    const common = new Common({ chain: 'ropsten' });
    const tx = Transaction.fromTxData(txObject, { common });
    const privateKey = account.wallet.privateKey;
    const signedTx = tx.sign(privateKey);
    let serializedTx = signedTx.serialize();
    const _serializedTx = '0x' + [...serializedTx].map(val => val.toString(16).padStart(2, '0')).join('');
    return _serializedTx;
}

export async function sendTransaction(provider: any, initialTx: any, profileIndex: number) {
    const _wallet = await getWalletByAddress(profileIndex, initialTx.from, 'Send Transaction');
    const account = await _wallet.getAccount(0);
    const wallet = new Wallet(account.privateKey, provider);
    const txObject = {
        gasPrice: initialTx.gasPrice || '0x12a05f200',
        gasLimit: initialTx.gas || initialTx.gasLimit || '0x5b8d80',
        value: initialTx.value || 0,
        chainId: initialTx.chainId || (await provider.getNetwork()).chainId,
        data: initialTx.data,
        to: initialTx.to,
        from: initialTx.from,
        nonce: initialTx.nonce,
        type: initialTx.type,
        accessList: initialTx.accessList,
        maxPriorityFeePerGas: initialTx.maxPriorityFeePerGas,
        maxFeePerGas: initialTx.maxFeePerGas,
    };
    return wallet.sendTransaction(txObject);
}

export interface DappMetadata {
    name: string;
    description: string;
    url: string;
    icons: string[];
}

export interface ConnectedDapp {
    chains: types.ChainNamespaces,
    profileIndex: number,
    dapp: DappMetadata,
}

export interface ConnectedDapps {
    [key: string]: ConnectedDapp;
}

export async function getConnectedSites(): Promise<ConnectedDapps> {
    const data = await storage.getDataObject(CT.CONNECTED_DAPPS_STORAGE);
    return data || {};
}

export async function setConnectedSites(data: ConnectedDapps) {
    return storage.storeData(CT.CONNECTED_DAPPS_STORAGE, JSON.stringify(data));
}

export async function addConnectedSite(dappData: ConnectedDapp) {
    const data = await getConnectedSites();
    data[dappData.dapp.url] = dappData;
    return setConnectedSites(data);
}

let initWalletCalled = false;
async function initWallet() {
    if (initWalletCalled) {return;}
    initWalletCalled = true;
    let index = await getProfilesCount();
    if (index === 0) {
        const mnemonic = await generateMnemonic();
        await generateProfile(mnemonic);
        await setCurrentProfileIndex(0);
        console.info('----PROFILE CREATED----');
    }
    else {
        // migration TODO remove
        let profiles = await getProfiles();
        profiles = profiles.map((p: types.Profile) => {
            if (!p.chainType) {
                p.chainType = CT.DEFAULT_CHAIN_TYPE;
            }
            if (!p.chains[p.chainType]) {
                p.chains = CT.DEFAULT_PROFILE_CHAINS;
            }
            return p;
        });
        await setProfiles(profiles);
    }
}

initWallet();

export async function extension (_dispatcher: Function) {
    dispatcher = _dispatcher('wallet');
    const extensions = {
        label: 'wallet',
        items: [
            {
                label: 'getProfiles',
                value: getProfiles,
            },
            {
                label: 'getProfile',
                value: getProfile,
            },
            {
                label: 'getCurrentProfileIndex',
                value: getCurrentProfileIndex,
            },
            {
                label: 'setCurrentProfileIndex',
                value: setCurrentProfileIndex,
            },
            {
                label: 'setCurrentProfile',
                value: setCurrentProfile,
            },
            {
                label: 'getCurrentProfile',
                value: getCurrentProfile,
            },
            {
                label: 'getAccounts',
                value: getAccounts,
            },
            {
                label: 'getConnectedSites',
                value: getConnectedSites,
            },
        ],
        events: [
            'Profiles',
            'Profile',
            'ProfileCurrentIndex',
            'ProfileCurrent',
            'Accounts',
            'Account',
            'Sites',
            'Site',
        ],
    };

    return {extensions};
}

export function useCurrentProfile() {
    const [profile, setProfile] = useState<types.Profile>();

    useEffect(() => {
      async function init() {
        const _profile = await getCurrentProfile();
        setProfile(_profile);
      }
      init();
    }, []);

    return profile;
}
