import {PLUGEM_INJ_MSG} from './config';

const plugemsInjection = `(function() {
  let PLUGEM_INJ_MSG = ${JSON.stringify(PLUGEM_INJ_MSG)};
  let PlugemClient, InjectionAdapter;

  const postMessage = (msg) => {
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }

  let requestID = 0;
  function _request(topic, data, id) {
      return new Promise((resolve, reject) => {
          window.addEventListener('message', (event) => {
              if (!event || !event.data) {
                  return;
              }
              // console.log('_request', event.data);
              const {type, topic: _topic, id: _id, data} = event.data;
              if (!type || !_topic) return;
              if (type !== PLUGEM_INJ_MSG.response) return;
              if (_topic !== topic) return;
              if (_id !== id) return;
              resolve(data);
          })
          postMessage({type: PLUGEM_INJ_MSG.request, topic, data, id});
      });
  }

  const plugems = {
    connect: () => {
      postMessage({type: PLUGEM_INJ_MSG.request, topic: 'dapp_connect_request'});
      return new Promise((resolve, reject) => {
        window.addEventListener('message', (event) => {
          if (!event || !event.data) {
            return;
          }

          const {type, topic, configs} = event.data;
          if (!type || !topic || !configs) return;
          if (type !== PLUGEM_INJ_MSG.response || topic !== 'dapp_connect_response') return;

          // TODO
          resolve();
        })
      });
    },
    request: (topic, args) => {
      const id = requestID;
      requestID += 1;
      return _request(topic, args, id)
    },
    response: (topic, data, id) => {
      postMessage({type: PLUGEM_INJ_MSG.response, topic, data, id});
    }
  }
  window.plugems = plugems;

  

  // Only for RNWebview: forwards dapp requests to RNWebview
  window.addEventListener('message', (event) => {
    if (!event || !event.data) {
      return;
    }
    if (!event.data.type) return;
    if (event.data.type !== PLUGEM_INJ_MSG.request || event.data.type !== PLUGEM_INJ_MSG.subscription) return;
    // console.log('injection direct message to RNWebview', event.data);
    postMessage(event.data);
  });
})();
`;

export default plugemsInjection;
