import { Database } from '@nozbe/watermelondb';
import {getAdapter} from './adapter';
import {
  BlockTree,
} from './Block';
import {ChatGroupIdentityModel, ChatMessageModel} from './Chat';

function initDatabase(models: any) {
  const adapter = getAdapter();
  const database = new Database({
    adapter,
    modelClasses: models,
  });

  return database;
}

const database = initDatabase([
    BlockTree,
    ChatGroupIdentityModel, ChatMessageModel,
]);
function getDatabase() {
    return database;
}

export default getDatabase;
