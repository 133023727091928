import * as React from 'react';
import { StyleSheet, Text, View, ScrollView, Platform } from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import SettingsList from '@ark-us/react-native-settings-list';
import { useTheme } from '@react-navigation/native';
import { useActions } from '../services/plugems/plugems';
import commonStyles from '../styles';
import StyledText from './StyledText';
import withObservables from '@nozbe/with-observables';
import { MenuItem as MenuItemType } from '../services/menus/types';
import TextButton from './TextButton';

export default function MenuWrap(props: {value: MenuItemType, path: string, menu: MenuItemType, index: number}) {
    const {menu, value, path, index = 0} = props;
    const actions = useActions();
    const { colors } = useTheme();
    const [submenu, setSubmenu] = React.useState(value.props.submenu);

    React.useEffect(() => {
        async function init() {
            if (!value.itemUrl) {return;}
            const items = await fetch(value.itemUrl);
            const itemJson = await items.json().catch(console.error);
            if (!itemJson) {return;}
            setSubmenu(itemJson.props.submenu);
        }
        init();
    }, [value]);

    React.useEffect(() => {
        setSubmenu(value.props.submenu);
    }, [value]);

    if (!actions?.navigation) {return (<Text>loading</Text>);}

    if (value.observeDescendants && (!submenu || !submenu.length)) {
        const Component = enhanceMenuItemRaw(MenuItemRaw);
        return <Component menu={menu} value={value} actions={actions} index={0} colors={colors} path={path} />;
    }

    if (!submenu) {
        return <StyledText>{JSON.stringify(value)}</StyledText>;
    }

    const _value = {...value, props: {...value.props, submenu}};
    return (
        <MenuItemsWrap key={index} menu={menu} value={_value} actions={actions} path={path} />
    );
}

export function MenuItemsWrap(props: {menu: MenuItemType, value: MenuItemType, path: string, actions: any, key: number}) {
    const {value, path, actions, menu, key} = props;
    const Component = enhanceMenuItemWrap(MenuItemWrap);
    const menuItems = (value.props.submenu || []).map((item: any, index: number) => {
        if (item.observeDescendantsCount) {
            return (
                <View key={index} style={[styles.row]}>
                    <Component key={index} menu={menu} value={item} actions={actions} path={path} index={index} />
                </View>
            );
        }
        return (
            <View key={index} style={[styles.row]}>
                <MenuItemWrap key={index} menu={menu} value={item} actions={actions} path={path} index={index} descendantsCount={undefined} />
            </View>
        );
    });

    let submenuComponent = (<></>);
    if (value.submenuPress) {
        const onPress = getAction(value.submenuPress, actions);
        submenuComponent = (<TextButton title="in context" onPress={onPress} />);
    }

    return (
        <ScrollView style={{backgroundColor: 'transparent', flex: 1}}>
            {menuItems}
            {submenuComponent}
        </ScrollView>
    );
}

export function MenuItemWrap(props: {menu: MenuItemType, value: MenuItemType, path: string, index: number, actions: any, descendantsCount: number | undefined}) {
    const { colors } = useTheme();
    const {menu, value, path, actions, index, descendantsCount} = props;
    const {submenu} = value.props;

    const title = (descendantsCount !== undefined)
        ? (value.props.title + ' ' + (submenu ? submenu.length : 0).toString() + '/' + descendantsCount)
        : value.props.title;

    const elem = MenuSwitchJsxItem({
        menu,
        value: {...value, props: {...value.props, title, screenTitle: value.props.title}},
        actions,
        key: index,
        colors,
        path,
    });

    const scrollViewProps = {scrollEnabled: false};

    return (
        <SettingsList
            borderColor={colors.border}
            scrollViewProps={scrollViewProps}
            indexStart={index}
        >
            {[elem]}
        </SettingsList>
    );
}

const enhanceMenuItemWrap = withObservables(['value'], ({ value }) => ({
    descendantsCount: value.observeDescendantsCount(),
}));

function MenuItemRaw({menu, value, actions, index, path, descendants}: any) {
    if (value.parseItem) {
        descendants = descendants.map((desc: any) => value.parseItem(desc));
    }

    const _value = {...value, props: {...value.props, submenu: descendants}};
    return (
        <MenuItemsWrap key={index} menu={menu} value={_value} actions={actions} path={path} />
    );
}

const enhanceMenuItemRaw = withObservables(['value'], ({ value }) => ({
    descendants: value.observeDescendants(),
}));

export function MenuSwitchItem({menu, value = {}, actions = {}, key = 0, colors, path}: {menu: any, value: any, actions: any, key: number, colors: any, path: string}) {
    if (typeof value !== 'object' || !value.type) {return <></>;}
    switch (value.type) {
        case 'function':
            return getAction(value, actions);
        default:
            return MenuSwitchJsxItem({menu, value, actions, key, colors, path});
    }
}

export function MenuSwitchJsxItem({menu, value = {}, actions = {}, key = 0, colors, path}: {menu: any, value: any, actions: any, key: number, colors: any, path: string}): React.ReactElement {
    if (typeof value !== 'object' || !value.type) {return <></>;}
    switch (value.type) {
        case 'Header':
            return MenuHeader({value, actions, key, colors});
        case 'Item':
            return MenuSubItem({menu, value, actions, key, colors, path});
        case 'IonIcon':
            return <IonIcon key={key} {...value.props} />;
        default:
            return <StyledText key={key}>no component</StyledText>;
    }
}

export function getAction(value: any = {}, actions: any = {}) {
    let path: string[] = value.subtype.split('.');
    let action = actions;
    while (path.length > 0) {
        const key = path.splice(0, 1);
        if (key.length < 1) {return () => console.error('no action found for ', value);}
        action = action[key[0]];
    }
    return () => action(...value.args);
}

export function MenuHeader({value = {}, actions = {}, key = 0, colors}: {value: any, actions: any, key: number, colors: any}) {
    return <SettingsList.Header headerStyle={{color: colors.card}} key={key} {...value.props}/>;
}

export function MenuSubItem({menu, value = {}, actions = {}, key = 0, colors, path}: {menu: any, value: any, actions: any, key: number, colors: any, path: string}) {
    let {props} = value;
    const parsedProps: any = {};

    // set the folder path
    if (props.onPress && typeof props.onPress === 'object' && props.onPress.subtype.includes('navigation') && !props.onPress.noMenuNavArgs) {
        if (props.onPress.args[1]) {
            props = JSON.parse(JSON.stringify(props));
            props.onPress.args[1].path = (path ? (path + ',') : path) + key;
            props.onPress.args[1].menu = JSON.parse(JSON.stringify(menu));
            props.onPress.args[1].value = JSON.parse(JSON.stringify(value));
            props.onPress.args[1].screenTitle = props.screenTitle || props.title;
        }
    }

    Object.keys(props).map((propKey: string) => {
        parsedProps[propKey] = (typeof props[propKey] === 'object' && props[propKey].type)
            ? MenuSwitchItem({menu, value: props[propKey], actions, key, colors, path})
            : props[propKey];
    });

    parsedProps.hasNavArrow = parsedProps.submenu ? true : false;
    let arrowStyle: any = {tintColor: colors.text};
    if (Platform.OS === 'web') {
        arrowStyle = {...arrowStyle, height: '10px', width: '10px'};
    }

    return (
        <SettingsList.Item
            key={key}
            backgroundColor={colors.background}
            titleStyle={{color: colors.text, ...commonStyles.textStyle}}
            arrowStyle={arrowStyle}
            {...parsedProps}
        />
    );
}

const styles = StyleSheet.create({
    row: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        margin: 0,
        flex: 1,
        borderRadius: commonStyles.roundness.radius,
    },
});
