import {Component} from 'react';
import {
    StyleSheet,
    Keyboard,
    ActivityIndicator,
    ScrollView,
    RefreshControl,
    View,
} from 'react-native';
import {WebView} from 'react-native-webview';
import web3Injection from '../services/web3/webview-web3';
import * as web3provider from '../services/web3/web3provider';
import plugemsInjection from '../services/plugems/webview-plugems';
import {handleRequest} from '../services/plugems/plugems';
import {PLUGEM_INJ_MSG} from '../services/plugems/config';

export const INJECTION_MSG = {
    send: 'ganas_provider_send',
    connect: 'ganas_provider_connect',
    receive: 'ganas_provider_receive',
};

const INJECTION_MSG_R: any = {};
Object.entries(INJECTION_MSG).forEach(([key, value]) => {INJECTION_MSG_R[value] = key});



// keeps the reference to the browser
let browserRef: any = null;
let dispatcher: Function;
let currentUrl: string = '';
let getCurrentUrl = () => {
    return currentUrl;
};

// initial url for the browser
const url = 'https://duckduckgo.com';
// const url = 'https://marks.provable.dev/?ipfs=QmUkAB1S1EtQczJH4mryYNkLyTGWNzkLTiw9Fv1WhrsG5S';
// const url = 'https://observablehq.com/@loredanacirstea/video-proof'

// functions to search using different engines
const searchEngines: any = {
    'google': (uri: string) => `https://www.google.com/search?q=${uri}`,
    'duckduckgo': (uri: string) => `https://duckduckgo.com/?q=${uri}`,
    'bing': (uri: string) => `https://www.bing.com/search?q=${uri}`,
};

// upgrade the url to make it easier for the user:
//
// https://www.facebook.com => https://www.facebook.com
// facebook.com => https://www.facebook.com
// facebook => https://www.google.com/search?q=facebook
function upgradeURL(uri: string, searchEngine = 'duckduckgo') {
    const isURL = uri.split(' ').length === 1 && uri.includes('.');
    if (isURL) {
        if (!uri.startsWith('http')) {
            return 'https://www.' + uri;
        }
        return uri;
    }
    // search for the text in the search engine
    const encodedURI = encodeURI(uri);
    return searchEngines[searchEngine](encodedURI);
}

const wait = (timeout: number) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
};

class Browser extends Component<any, any> {
    constructor(props: any, state: any) {
        super(props);
        this.state.currentURL = props.url || url;
        this.onRefresh = this.onRefresh.bind(this);
    }

    state: any = {
        currentURL: url,
        urlText: url,
        title: '',
        canGoForward: false,
        canGoBack: false,
        incognito: false,
        // change configurations so the user can
        // better control the browser
        config: {
            detectorTypes: 'all',
            allowStorage: true,
            allowJavascript: true,
            allowCookies: true,
            allowLocation: true,
            allowCaching: true,
            cacheMode: 'LOAD_DEFAULT',
            defaultSearchEngine: 'duckduckgo',
        },
        refreshing: false,
    };

    componentDidMount() {
        currentUrl = this.props.url;
        dispatcher && dispatcher('CurrentUrl')(this.props.url);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.url !== this.props.url) {
            this.setState({currentURL: this.props.url});
            currentUrl = this.props.url;
            dispatcher && dispatcher('CurrentUrl')(this.props.url);
        }
    }


    // get the configuration, this allows us to change
    // configurations for incognito mode
    get config() {
        const {incognito, config} = this.state;
        if (incognito) {
            return {
                ...config,
                allowStorage: false,
                allowCookies: false,
                allowLocation: false,
                allowCaching: false,
            };
        }
        return config;
    }

    // toggle incognito mode
    toggleIncognito = () => {
        this.setState({
            incognito: !this.state.incognito,
        });
        this.reload();
    };

    // load the url from the text input
    loadURL = () => {
        const {config, urlText} = this.state;
        const { defaultSearchEngine } = config;
        const newURL = upgradeURL(urlText, defaultSearchEngine);

        this.setState({
            currentURL: newURL,
            urlText: newURL,
        });
        Keyboard.dismiss();
    };

    // update the text input
    updateUrlText = (text: string) => {
        this.setState({
            urlText: text,
        });
    };


    // go to the next page
    goForward = () => {
        if (browserRef && this.state.canGoForward) {
            browserRef.goForward();
        }
    };

    // go back to the last page
    goBack = () => {
        if (browserRef && this.state.canGoBack) {
            browserRef.goBack();
        }
    };

    // reload the page
    reload = () => {
        if (browserRef) {
            browserRef.reload();
        }
    };

    // set the reference for the browser
    setBrowserRef = (browser: any) => {
        browserRef = browser;
    };

    // called when there is an error in the browser
    onBrowserError = (syntheticEvent: any) => {
        const {nativeEvent} = syntheticEvent;
        console.warn('--WebView error: ', nativeEvent);
    };

    // called when the webview is loaded
    onBrowserLoad = (syntheticEvent: any) => {
        const {canGoForward, canGoBack, title} = syntheticEvent.nativeEvent;
        this.setState({
            canGoForward,
            canGoBack,
            title,
        });
    };

    // called when the navigation state changes (page load)
    onNavigationStateChange = (navState: any) => {
        const {canGoForward, canGoBack, title, url} = navState;
        currentUrl = url;
        dispatcher && dispatcher('CurrentUrl')(url);
        this.setState({
            canGoForward,
            canGoBack,
            title,
        });
    };

    // can prevent requests from fulfilling, good to log requests
    // or filter ads and adult content.
    filterRequest = (request: any) => {
        return true;
    };

    _postMessage(injection: string) {
        let timeout = 0;
        if (!browserRef || !browserRef.current) {timeout = 1000;}

        setTimeout(() => {
            try {
                browserRef.injectJavaScript(injection);
            } catch (e) {
                console.error(e);
            }
        }, timeout);
    }

    postMessage(message: any) {
        if (!message) {return;}
        const injection = `
            (function(){
                window.postMessage(${JSON.stringify(message)},'*');
            })();
            true;
        `;
        this._postMessage(injection);
    }

    postMessageString(message: any) {
        if (!message) {return;}
        const injection = `
            (function(){
                window.postMessage('${JSON.stringify(message)}','*');
            })();
            true;
        `;
        this._postMessage(injection);
    }

    async tryHandleWeb3ProviderRequest(data: any): Promise<boolean> {
        if (data.type === INJECTION_MSG.connect) {
            // TODO mark where you choose your profile
            // TODO for chainid request, you need to choose a default chain and profile
            // const accounts = await (await web3signer.getAccounts(0)).map((account: any) => account.address);
            // this.postMessageString({ type: INJECTION_MSG.connect, message: {...data.message, result: accounts }});
            // return true;
        }

        if (data.type !== INJECTION_MSG.send) {return false;}
        if (!data.message || !data.message.params) {return false;}

        const profileIndex = await this.props.actions.wallet.getCurrentProfileIndex();
        const payloadMessage = await web3provider.jsonrpcRequest(data, profileIndex);
        this.postMessageString({ type: INJECTION_MSG.send, message: payloadMessage });
        return true;
    }

    async tryHandlePlugemsRequest(data: any): Promise<boolean> {
        if (data.type !== PLUGEM_INJ_MSG.request) {return false;}
        if (!data.topic) {return false;}

        const topic = data.topic.replace(/_/g, '.');
        const {result, error} = await handleRequest(topic, data.data);
        this.postMessage({
            type: PLUGEM_INJ_MSG.response,
            topic: data.topic,
            id: data.id,
            data: result,
            error,
        });
        return true;
    }

    async tryHandlePlugemsResponse(data: any) {
        if (data.type !== PLUGEM_INJ_MSG.response) {return false;}
        if (!data.topic) {return;}

        const topic = data.topic.replace(/_/g, '.');
        return handleRequest(topic, [data.id, data.data]);
    }

    // called when the browser sends a message using "window.ReactNativeWebView.postMessage"
    onBrowserMessage = async (event: any) => {
        let data = event.nativeEvent.data;
        if (!data) {return;}
        try {
            data = JSON.parse(data);
        } catch (e) {
            console.log('--onBrowserMessage data, e--', data, e);
        return;
        }

        if (!data.type) {return;}

        let handled = await this.tryHandleWeb3ProviderRequest(data);
        if (!handled) {handled = await this.tryHandlePlugemsRequest(data);}
        if (!handled) {return this.tryHandlePlugemsResponse(data);}
    };

    onRefresh = () => {
        this.setState({refreshing: true});
        wait(1000).then(() => this.setState({refreshing: false}));
        this.reload();
    }

    render() {
        const {config, state} = this;
        const {currentURL, refreshing} = state;
        const iconColor = '#ccc';
        const iconSize = 35;
        const {theme, chainId} = this.props;
        const {colors} = theme;

        const injectedJavaScript = web3Injection(chainId) + `
${plugemsInjection}

true;
`;

        const renderLoading = (
            <View style={styles.loadingOrErrorView}>
                <ActivityIndicator size="large" color={colors.text} />
            </View>
        );

        return (
            <ScrollView
                contentContainerStyle={{flex: 1, backgroundColor: colors.background}}
                style={{backgroundColor: colors.background}}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={this.onRefresh}
                    />
                }
            >
            <WebView
                ref={this.setBrowserRef}
                originWhitelist={['*']}
                source={{uri: currentURL}}
                onLoad={this.onBrowserLoad}
                onError={this.onBrowserError}
                onNavigationStateChange={this.onNavigationStateChange}
                renderLoading={() => renderLoading}
                onShouldStartLoadWithRequest={this.filterRequest}
                onMessage={this.onBrowserMessage}
                dataDetectorTypes={config.detectorTypes}
                thirdPartyCookiesEnabled={config.allowCookies}
                domStorageEnabled={config.allowStorage}
                javaScriptEnabled={config.allowJavascript}
                geolocationEnabled={config.allowLocation}
                cacheEnabled={config.allowCaching}
                injectedJavaScript={injectedJavaScript}
                allowsInlineMediaPlayback={ true }
                cacheMode={config.cacheMode}
                style={{backgroundColor: 'transparent'}}
                containerStyle={{backgroundColor: colors.background}}
            />
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        textShadowColor: 'black',
        textShadowOffset: {
          height: 0,
          width: 0,
        },
        textShadowRadius: 1,
    },
    disabled: {
        opacity: 0.3,
    },
    browserContainer: {
        flex: 2,
    },
    loadingOrErrorView: {
        position: 'absolute',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#808080',
    },
});

export default Browser;

export async function extension (_dispatcher: Function) {
    dispatcher = _dispatcher('browser');
    const extensions = {
        label: 'browser',
        items: [
            {
                label: 'getCurrentUrl',
                value: getCurrentUrl,
            },
        ],
        events: [
            'CurrentUrl',
        ],
    };

    return {extensions};
}
