import { useEffect, useState } from 'react';
import { EventEmitter } from 'events';
import * as wallet from '../wallet/accounts';
import * as filesystem from '../filesystem/index';
import * as ipfs from '../ipfs';
import * as storage from '../storage';
import * as torch from '../torch';
import * as walletconnect from '../walletconnect/walletconnect';
import * as navigation from '../navigation';
import * as web3provider from '../web3/web3provider';
import {extension as cosmosExtension} from '../web3/cosmos/cosmos';
import {extension as browserExtension} from '../../components/Browser';
import {extension as bookmarkExtension} from '../menus/menu';
import  {extension as chatExtension} from '../chat/chat';
import  {extension as blocktreeExtension} from '../blocktree/index';

const actionsMap: any = {};
const eventManager = new EventEmitter();
actionsMap.events = eventManager;
actionsMap.events.plugems = {};

const dispatcherCallb = (label: string) => (topic: string) => (...args: any) => {
    const fulltopic = `${label}.${topic}`;
    eventManager.emit(fulltopic, ...args);
};

// TODO this should be taylor
async function extend(extensionInit: Function, args: any = []) {
    const {extensions} = await extensionInit(dispatcherCallb,...args);
    actionsMap[extensions.label] = {};
    actionsMap.events.plugems[extensions.label] = {};
    extensions.items.forEach((item: any) => {
        actionsMap[extensions.label][item.label] = item.value;
    });
    extensions.events && extensions.events.forEach((topic: string) => {
        actionsMap.events.plugems[extensions.label][`on${topic}`] = (callb: Function) => {
            const fulltopic = `${extensions.label}.${topic}`;
            actionsMap.events.on(fulltopic, callb);
        };

        actionsMap.events.plugems[extensions.label][`off${topic}`] = (callb: Function) => {
            const fulltopic = `${extensions.label}.${topic}`;
            actionsMap.events.removeListener(fulltopic, callb);
        };
    });
}

interface HandleRequestReturn {
    result: any;
    error: Error | null;
}

export async function handleRequest(topic: string, args: any): Promise<HandleRequestReturn> {
    const path = topic.split('.');
    let action = actionsMap;
    while (path.length > 0) {
        if (!action) {break;}
        const key = path.splice(0, 1)[0];
        action = action[key];
    }
    if (!action) {
        return {result: null, error: new Error("Plugems action not found")};
    }

    // handle args or data
    if (!(args instanceof Array)) {args = [args];}
    try {
        const result = await action(...args);
        return {result, error: null};
    } catch (e: any) {
        return {result: null, error: e};
    }
}

let requestID = 0;
export async function initiateRequest(callback: Function) {
    const id = requestID;
    requestID += 1;
    const {topic, args } = callback(id);
    return handleRequest(topic, args);
}

let actionsInitializing = false;
let actionsInitialized = false;
export async function initActions () {
    if (actionsInitializing || actionsInitialized) {return;}
    actionsInitializing = true;
    console.log('========INIT ACTIONs=======');
    await extend(filesystem.extension);
    await extend(ipfs.extension);
    await extend(storage.extension);
    await extend(torch.extension);
    await extend(navigation.extension);
    await extend(wallet.extension);
    await extend(web3provider.extension);
    await extend(browserExtension);
    await extend(bookmarkExtension);
    await extend(walletconnect.extension);
    await extend(chatExtension);
    await extend(blocktreeExtension);
    await extend(cosmosExtension);
    actionsInitializing = false;
    actionsInitialized = true;
    return actionsMap;
}

export function getActions() {
    return actionsMap;
}

let initPromise: any;
export function useActions() {
    const [actions, setActions] = useState<any>();

    useEffect(() => {
      async function init() {
        let actions = getActions();
        if (!actionsInitialized && !actionsInitializing) {initPromise = initActions();}
        if (initPromise) {actions = await initPromise;}
        setActions(actions);
      }
      init();
    }, []);

    return actions;
}
