const menuJson: any = [
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "ide - new mark",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": "https://mark.provable.dev/?m=e"
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "qrcode scan",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": "https://mark.provable.dev/?ipfs=Qmc3xw5NESwtFvYc1M6D4edLJmbrsKqHCYGD8nkPESgfcu&m=p"
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "mobile apps",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": "https://ctzurcanu.github.io/mapps"
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "record audio",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": "https://mark.provable.dev/?ipfs=QmbCmVnFfzQxvwYX6ghvgPihd4eewPPxxhrKq3qTxd8br6&m=p"
            }]}
        }
    },
];

export default menuJson;
