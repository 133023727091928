import React, {useState, useEffect, useCallback} from 'react';
import {StyleSheet, View, Text, TextInput, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import withObservables from '@nozbe/with-observables';
import { Q } from '@nozbe/watermelondb';
import type { Routes } from '../../views/Routes';
import { GiftedChat, IMessage, Bubble, InputToolbar, Avatar } from 'react-native-gifted-chat';
import {chatInstance} from '../../services/chat/chat';
import commonStyles from '../../styles';
import CustomAvatar from './Avatar';
import {modelToChatMessage, chatMessageToIMessage} from '../../services/chat/utils';
import StyledText from '../StyledText';

// const identity = {
//     id: '0x39B1BF12E9e21D78F0c76d192c26d47fa710Ec98',
//     title: 'me',
//     salty: {
//         identifier: {publicKey: '232385faea4c0fca2c867bfb7ca74f634178ee0bc13364ee738e02cd4318e839'}
//     }
// }

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;

type PropsChatPageByHash = NativeStackScreenProps<Routes, 'ChatPageByHash'>;
export function ChatPageByHash({ navigation, route }: PropsChatPageByHash): React.ReactElement {
    const hash = route.params.hash;
    // TODO
    const datatype = route.params.type;


    React.useEffect(() => {
        if (!hash) {return;}
        async function redirect() {
            const {group, identity} = await chatInstance.getChatByHash(hash);
            navigation.pop(); // pop this page
            navigation.navigate('ChatPage', {group, identity});
        }
        redirect();
    }, [hash, navigation]);

    return (<StyledText>finding your data..</StyledText>);
}

type Props = NativeStackScreenProps<Routes, 'ChatPage'>;
export default function Chat({ navigation, route }: Props): React.ReactElement {
    const group = route.params.group;
    const contact = group.participants[0];
    const identity = route.params.identity;
    const initMessage = route.params.message;
    const topic = group.id;
    const { colors } = useTheme();

    chatInstance.setCurrentGroup(group);

    useEffect(() => {
        chatInstance.connectToGroup(group);
        return () => chatInstance.disconnectContact(topic);
    }, [group, topic]);

    const Component = enhanceChat(ChatInner);

    return <Component navigation={navigation} collection={chatInstance.messageCollection} identity={identity} group={group} initMessage={initMessage} topic={topic} colors={colors} />;
}

const enhanceChat = withObservables(['topic', 'collection'], ({ topic, collection }) => ({
    records: collection.query(Q.where('groupid', topic)).observe(),
}));

function ChatInner({navigation, identity, group, initMessage, topic, colors, records}: any) {
    const messages = records.map((r: any) => chatMessageToIMessage(modelToChatMessage(r)));
    const contact = group.participants.find((part: any) => part.id !== identity.id);

    const onSend = useCallback(async (messages = []) => {
        chatInstance.sendMessage(topic, messages[0]);
    }, [topic]);

    const onPressLink = (url: string) => {
        navigation.navigate('BrowserPage', {url});
    };

    const renderBubble = (props: any) => {
        return (<Bubble
            {...props}
            textStyle={{
                left: {
                  color: colors.text,
                  ...commonStyles.textStyles,
                },
                right: {
                    color: colors.text,
                    ...commonStyles.textStyles,
                },
            }}
            customTextStyle={{
                ...commonStyles.textStyles,
            }}
            wrapperStyle={{
                left: {
                  backgroundColor: 'rgb(58, 58, 58)',
                },
                right: {
                    backgroundColor: 'rgb(26, 55, 90)',
                },
            }}
        />);
    };

    const renderInputToolbar = useCallback((props: any) => {
        const _props: any = {
            ...props,
            containerStyle: {
                ...(props.containerStyle || {}),
                backgroundColor: colors.background,
            },
            textInputStyle: {
                ...commonStyles.textStyles,
                color: colors.text,
            },
            text: initMessage ? initMessage.text : '',
        };
        return (<CustomInputToolbar colors={colors} initMessage={initMessage} props={_props} />);
    }, [initMessage, colors]);

    const renderAvatar = (props: any) => {
        return (
        <CustomAvatar
            // arkName={props.currentMessage.user.arkName}
            arkName={contact.name}
            size={40}
        />
        );
    };

    const parsedMessages = messages.map((msg: IMessage) => {
        // @ts-ignore
        msg.user.arkName = msg.user._id === identity.accounts[0].address
            ? identity.name : contact.name;
        return msg;
    })

    return (
        <View style={{flex: 1, marginBottom: 10}}>
        <GiftedChat
            messages={messages}
            scrollToBottom
            inverted={false}
            // @ts-ignore
            onSend={messages => onSend(messages)}
            user={{
                _id: identity.accounts[0].address,
                // @ts-ignore
                arkName: identity.name,
            }}
            renderBubble={renderBubble}
            renderInputToolbar={renderInputToolbar}
            renderAvatar={renderAvatar}
            parsePatterns={(linkStyle) => [
                // { type: 'phone', style: linkStyle, onPress: this.onPressPhoneNumber },
                // { pattern: /#(\w+)/, style: { ...linkStyle, styles.hashtag }, onPress: this.onPressHashtag },
                // { type: 'url', style: linkStyle, onPress: onPressLink },
                { pattern: urlRegex, style: linkStyle, onPress: onPressLink },
            ]}
        />
        </View>
    );
}

function CustomInputToolbar(_props: any) {
    const {colors, initMessage, props} = _props;
    const [msg, setMsg] = useState<string>(initMessage ? initMessage.text : '');

    useEffect(() => {
        if (!initMessage || !initMessage.text) {return;}
        const text = initMessage.text;
        const addl = msg.length === 0 ? text : (
            msg[msg.length - 1] === ' ' ? text : (' ' + text)
        );
        setMsg(addl);
    }, [initMessage, msg]);

    const onTextChanged = useCallback((newmsg: string) => {
        setMsg(newmsg);
    }, []);

    return (
        <InputToolbar
        {...props}
        containerStyle={{
            ...(props.containerStyle || {}),
            backgroundColor: colors.background,
        }}
        textInputStyle={{
            ...commonStyles.textStyles,
            color: colors.text,
        }}
        text={msg}
        onTextChanged={onTextChanged}
    />
    );
}
