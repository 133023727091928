import {ethers} from 'ethers';
import {generateName} from '@ark-us/name';
import {ChatGroupIdentityModel} from '../database/Chat';
import {MenuItem} from '../menus/types';
import {ChatGroup, Contact} from './types';
import {Profile} from '../wallet/types';
import { ChatMessage, ChatMessageRootData, IMessageExtra } from './types';
import {ChatMessageModel} from '../database/Chat';

export function getTopicString(ids: string[]) {
    return ids.sort((a: string, b: string) => a.localeCompare(b)).join('_');
}

export function getTopic(ids: string[]) {
    return ethers.utils.id(getTopicString(ids));
}

function participantToContact(participant: string): Contact {
    return {
        id: participant,
        name: generateName(participant),
    };
}

export function modelToGroup(value: ChatGroupIdentityModel): ChatGroup {
    return {
        id: value.id,
        name: generateName(value.id),
        blockHeight: value.blockHeight,
        identity: value.identityAddress,
        participants: value.getParticipants().map(participantToContact),
        createdAt: new Date(value.createdAt),
    };
}

export function groupToMenuItem(value: ChatGroup, identity: Profile): MenuItem {
    return {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: value.name.name + ' (' + value.participants.length + ')',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['ChatPage', {group: value, identity, screenTitle: '@' + value.name.attribute + ' ' + value.name.genus}]},
        },
    };
}

export function modelToChatMessage(value: ChatMessageModel): ChatMessage {
    return {
        id: value.id,
        hash: value.hash,
        groupId: value.groupId,
        blockHeight: value.blockHeight,
        latlng: value.latlng,
        text: value.text,
        userId: value.userId,
        createdAt: new Date(value.createdAt).getTime(),
        data: value.data ? JSON.parse(value.data) : undefined,
    };
}

export function chatMessageToIMessage(message: ChatMessage): IMessageExtra {
    const data = message.data ? (' ' + JSON.stringify(message.data)) : '';
    return {
        _id: message.id,
        text: message.text + data,
        createdAt: message.createdAt,
        user: {_id: message.userId},
        data: message.data,
    };
}

export function imessageToChatMessage(topic: string, message: IMessageExtra): ChatMessageRootData {
    return {
        id: message._id.toString(),
        groupId: topic,
        text: message.text,
        createdAt: message.createdAt instanceof Date ? message.createdAt.getTime() : new Date(message.createdAt).getTime(),
        userId: message.user._id.toString(),
        latlng: '',
        data: message.data,
    };
}
