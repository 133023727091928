import { Model } from '@nozbe/watermelondb';
import { field, text, date } from '@nozbe/watermelondb/decorators';

export class BlockTree extends Model {
  static table = 'blocktree'

  @field('label') label: any
  @field('parent_id') parentId: any
  @field('predecessor_id') predecessorId: any
  @field('children') children: any
  @field('last_child_id') lastChildId: any
  @field('hash') hash: any
  @field('height') height: any
  @field('type') type: any
  @field('content_id') contentId: any
}

