import React from 'react';
import {
  View,
} from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import type { Routes } from '../views/Routes';
import Menu from '../components/Menu';
import StyledText from '../components/StyledText';
import {BlockTreeBlock} from '../components/blocktree/BlockTreeBlock';


type Props = NativeStackScreenProps<Routes, 'BlockTreePage'>;
export default function BlockTreePage({ navigation, route }: Props): React.ReactElement {
    const path = route.params.path;
    const value = route.params.value;
    const menu = route.params.menu;

    if (!value || !menu) {return <StyledText>no menu</StyledText>;}

    return (
        <View style={{flex: 1}}>
            <BlockTreeBlock value={value._value} />
            <Menu menu={menu} path={path} value={value} index={0}/>
        </View>
    );
}
