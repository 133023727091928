const menuJson: any = [
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'QRcode Scanner',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['QrcodeScannerPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'Files',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['FileSystemPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'Sensors',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['SensorsPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'Torch',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['TorchPage']},
        },
    },
];

export default menuJson;
