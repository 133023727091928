import {ethers} from 'ethers';
import SocketioTransport from './socketio';
import { v4 as uuidv4 } from 'uuid';
import { IMessage } from 'react-native-gifted-chat';
import {generateName} from '@ark-us/name';
import {getTopic} from './utils';
import getDatabase from '../database/index';
import { MenuItem } from '../menus/types';
import { modelToGroup, groupToMenuItem, imessageToChatMessage } from './utils';
import {Contact, ChatGroupDb, ChatMessage, ChatGroup, ChatMessageRootData, IMessageExtra} from './types';
import {Profile} from '../wallet/types';
import {getRootRecord, addBlockWrite, dbWrite} from '../blocktree/index';
import {BlockReference} from '../blocktree/types';
import {weekdayNames, monthNames} from '../blocktree/utils';
import { Q } from '@nozbe/watermelondb';

const database = getDatabase();
const messageCollection = database.get('messages');
const groupCollection = database.get('chatgroups');


const tconfig = {server: 'wss://socketio.provable.dev'};
const chatRoot = ['chats'];

const groupMenu: MenuItem = {
    type: 'Item',
    props: {
        title: 'contacts',
        hasNavArrow: true,
        submenu: [],
    },
};

class Chat {
    messages: any = {};
    transport?: any;
    // @ts-ignore
    identity: Profile;
    isReady: boolean = false;
    currentChatGroup?: ChatGroup;
    groupCollection: any = groupCollection;
    messageCollection: any = messageCollection;

    async init(identity: Profile) {
        if (this.isReady) {return;}
        this.isReady = true;
        this.identity = identity;
        this.transport = new SocketioTransport({config: tconfig, identity: {id: identity.accounts[0].address}});
        await this.transport.init();
    }

    disconnect() {
        this.transport.disconnect();
        this.isReady = false;
    }

    getTopic(contact: Contact) {
        return getTopic([this.identity.accounts[0].address, contact.id]);
    }

    computeDataRoot(data: ChatMessageRootData) {
        return ethers.utils.solidityKeccak256(
            ['string', 'string', 'string', 'uint256', 'string', 'string'],
            [data.id, data.groupId, data.text, data.createdAt, data.userId, data.data || ''],
        );
    }

    initTopic(topic: string) {
        if (this.messages[topic]) {return;}
        this.messages[topic] = {};
        this.messages[topic].messageMap = {};
        this.messages[topic].connected = true;
    }

    async connectToGroup(group: ChatGroup, onMessage?: Function) {
        for (let participant of group.participants) {
            if (participant.id === this.identity.accounts[0].address) {continue;}
            await this.connectToContact(participant, onMessage);
        }
    }

    async connectToContact(contact: Contact, onMessage?: Function) {
        const topic = this.getTopic(contact);

        this.initTopic(topic);

        const _onMessage = async (message: IMessage) => {
            onMessage && onMessage(message);
            if (this.messages[topic].messageMap[message._id]) {return;}

            this.messages[topic].messageMap[message._id] = true;
            await this.addMessage(topic, message);
        };
        return this.transport.initiateConnect(contact, topic, _onMessage);
    }

    disconnectContact(topic: string) {
        this.messages[topic].connected = false;
        this.transport.socket.off(topic);
    }

    async sendMessage(topic: string, message: IMessage) {
        this.initTopic(topic);
        if (this.messages[topic].messageMap[message._id]) {return;}

        this.messages[topic].messageMap[message._id] = true;
        this.transport.sendMessage(topic, message);
        await this.addMessage(topic, message);
    }

    async addMessageBlock(topic: string, userId: string, data: BlockReference, message: ChatMessageRootData) {
        const root = getRootRecord();
        // TODO page
        const page = '1';
        const hint = message.text.slice(0, 5) + '..' + message.text.slice(message.text.length - 5);
        const topicName = generateName(topic);
        const userName = generateName(userId);
        const path = chatRoot.concat([
            topicName.name,
            userName.name,
            page,
            hint,
        ]);
        const record = await addBlockWrite(path, data, root);
        const date = new Date(message.createdAt);
        const timepath: string[] = [
            'time',
            date.getUTCFullYear().toString() + ' (year)',
            monthNames[date.getMonth()] + ' (month)',
            // weekdayNames[date.getUTCDay()],
            date.getUTCDate().toString().padStart(2, '0') + ' (day)',
            date.getUTCHours().toString().padStart(2, '0') + ' (hour)',
            date.getUTCMinutes().toString().padStart(2, '0') + ' (minute)',
            date.getUTCSeconds().toString().padStart(2, '0') + ' (second)',
            date.getUTCMilliseconds().toString().padStart(3, '0') + ' (millisecond)',
        ];
        await addBlockWrite(timepath, data, root);
        const locationPath: string[] = [
            'location',
            'Europe',
            'Germany',
            'Berlin',
            'Skalitzer Straße',
        ];
        await addBlockWrite(locationPath, data, root);
        return record;
    }

    async addContact(contact: Contact) {
        const topic = this.getTopic(contact);

        const timestamp = new Date().getTime();
        const identityAddress = this.identity.accounts[0].address;
        // group data
        const group: any = {
            id: topic,
            hash: topic,
            identityAddress,
            participants: [identityAddress, contact.id],
            createdAt: timestamp,
        };
        // add message to the group's messages chain - first block
        const message = {
            _id: uuidv4(),
            text: 'Group created.',
            data: group,
            createdAt: timestamp,
            user: {_id: identityAddress},
        };
        const finalMessage = await this.addMessage(topic, message);

        // add group data to the chat group database too
        group.blockHeight = finalMessage.blockHeight;
        await this.storeGroup(group);
    }

    setCurrentGroup(group: ChatGroup) {
        this.currentChatGroup = group;
    }

    getCurrentGroup() {
        return this.currentChatGroup;
    }

    // stores message, adds it to the messages chain
    async addMessage(topic: string, message: IMessageExtra): Promise<ChatMessage> {
        const messageData: ChatMessageRootData = {
            ...imessageToChatMessage(topic, message),
        };
        const hash = this.computeDataRoot(messageData);
        const messageBlock: BlockReference = {
            hash,
            type: 'messages',
        };
        const block = await this.addMessageBlock(topic, messageData.userId, messageBlock, messageData);
        const _message: ChatMessage = {
            hash,
            ...messageData,
            // @ts-ignore
            blockHeight: block.height,
        };
        await this.storeMessage(_message);
        return _message;
    }

    async storeMessage(message: ChatMessage) {
        const newvalue = await database.write(async () => {
            const _value = await messageCollection.create(value => {
                value._raw.id = message.id;
                // @ts-ignore
                value.groupId = message.groupId;
                // @ts-ignore
                value.blockHeight = message.blockHeight;
                // @ts-ignore
                value.latlng = message.latlng;
                // @ts-ignore
                value.text = message.text;
                // @ts-ignore
                value.createdAt = message.createdAt;
                // @ts-ignore
                value.userId = message.userId;
                // @ts-ignore
                value.data = JSON.stringify(message.data);
                // @ts-ignore
                value.hash = message.hash;
            });
            return _value;
        });
        return newvalue;
    }

    async storeGroup(group: ChatGroupDb) {
        const newvalue = await database.write(async () => {
            const _value = await groupCollection.create(value => {
                value._raw.id = group.id;
                // @ts-ignore
                value.blockHeight = group.blockHeight;
                // @ts-ignore
                // value.hash = group.hash
                // @ts-ignore
                value.identityAddress = group.identityAddress;
                // @ts-ignore
                value.participants = JSON.stringify(group.participants);
                // @ts-ignore
                value.createdAt = group.createdAt;
            });
            return _value;
        });
        return newvalue;
    }

    getGroupMenu() {
        groupMenu.observeDescendants = () => groupCollection.query().observe();
        groupMenu.observeDescendantsCount = () => groupCollection.query().observeCount();
        if (this.isReady) {
            groupMenu.parseItem = (model: any) => groupToMenuItem(modelToGroup(model), this.identity);
        }
        return groupMenu;
    }

    async getChatByHash(hash: string) {
        const messageRecord = (await messageCollection.query(
            Q.where('hash', hash),
        ).fetch())[0];

        const groupRecord = (await groupCollection.query(
            // @ts-ignore
            Q.where('id', messageRecord.groupId),
        ).fetch())[0];
        // @ts-ignore
        const group = modelToGroup(groupRecord);
        return {
            identity: this.identity,
            group,
        };
    }
}

const chatInstance = new Chat();
export {chatInstance};

export async function extension (_dispatcher: Function) {
    const extensions = {
        label: 'chat',
        items: [
            {
                label: 'sendMessage',
                value: (topic: string, message: any) => chatInstance.sendMessage(topic, message),
            },
            {
                label: 'getCurrentChat',
                value: () => {
                    return {
                        identity: chatInstance.identity,
                        group: chatInstance.getCurrentGroup(),
                    };
                },
            },
        ],
        events: [
        ],
    };

    return {extensions};
}
