import type {ArkName} from '@ark-us/name/src/types';
import {SvgXml} from 'react-native-svg';

const avatarSvg = (name: string, avatarColor: string, textColor: string) => `
<svg width="32" height="32" viewBox="0 0 1024 1024" opacity="0.4">
<ellipse cx="512" cy="512" fill="${avatarColor}" id="svg_1" rx="512" ry="512" stroke="#000000" stroke-width="0"/>
<text fill="${textColor}"  font-size="550" id="svg_2" stroke="#000000" stroke-width="6" text-anchor="middle" x="512" y="641.58">${name}</text>
</svg>`;

export default function Avatar({arkName, size}: {arkName: ArkName, size: number}) {
    const name = arkName.attribute[0] + arkName.genus[0];
    const source = avatarSvg(name, arkName.color[1], arkName.avatar.complement);
    return (
        <SvgXml xml={source} width={size} height={size} />
    );
}
