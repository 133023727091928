import * as React from 'react';
import {StyleSheet, View, TextInput, TouchableOpacity} from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useTheme } from '@react-navigation/native';
import type { Routes } from '../../views/Routes';
import Menu from '../Menu';
import { useActions } from '../../services/plugems/plugems';
import IconButton from '../IconButton';
import {chatInstance} from '../../services/chat/chat';
import { MenuItem } from '../../services/menus/types';
import StyledText from '../StyledText';

type Props = NativeStackScreenProps<Routes, 'ContactsPage'>;
export default function ContactsPage({ navigation, route }: Props): React.ReactElement {
    const actions = useActions();
    const { colors } = useTheme();
    const [groupsMenu, setGroupsMenu] = React.useState<MenuItem>();
    const [identity, setIdentity] = React.useState<any>();

    React.useEffect(() => {
        async function init() {
            if (!actions?.chat) {return;}
            const profile = await actions.wallet.getCurrentProfile();
            if (!profile) {return;}
            setIdentity(profile);
            await chatInstance.init(profile);
            const groupsMenu = chatInstance.getGroupMenu();
            setGroupsMenu(groupsMenu);
        }
        init();
    }, [actions]);

    const onAddContact = React.useCallback(() => {
        if (!identity) {return;}
        navigation.navigate('NewContact', {id: identity.accounts[0].address});
    }, [identity, navigation]);

    if (!groupsMenu) {return <StyledText>create a profile first</StyledText>;}

    return (
        <View style={{flex: 1}}>
            <IconButton styleButton={{float: 'right'}} icon="add" onPress={onAddContact} />
            <Menu value={groupsMenu} path={''} menu={groupsMenu} index={0}/>
        </View>
    );
}
