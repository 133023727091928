import RobotoCondensedRegular from './assets/fonts/RobotoCondensed-Regular.ttf';
import RobotoCondensedLight from './assets/fonts/RobotoCondensed-Light.ttf';
import RobotoMonoRegular from './assets/fonts/RobotoMono-Regular.ttf';

// Generate required css
const FontCSS = `
@font-face {
  src: url(${RobotoCondensedRegular});
  font-family: RobotoCondensed-Regular;
}
@font-face {
  src: url(${RobotoCondensedLight});
  font-family: RobotoCondensed-Light;
}
@font-face {
  src: url(${RobotoMonoRegular});
  font-family: RobotoMono-Regular;
}
`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = FontCSS;
} else {
  style.appendChild(document.createTextNode(FontCSS));
}

// Inject stylesheet
document.head.appendChild(style);
