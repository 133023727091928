import React, {useCallback, useState, useEffect} from 'react';
import {
  View,
  StyleSheet,
  Button,
  TextInput,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import { PressableOpacity } from 'react-native-pressable-opacity';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import IonIcon from 'react-native-vector-icons/Ionicons';
import type { Routes } from '../views/Routes';
import PageWrap from '../components/PageWrap';
import { getActions } from '../services/plugems/plugems';
import commonStyles from '../styles';
import StyledText from '../components/StyledText';

type Props = NativeStackScreenProps<Routes, 'WalletConnectPage'>;
function WalletConnectPage({navigation, route}: Props) {
  const routeUrl = route.params?.uri;

  const { colors } = useTheme();
  let textStyles = { ...styles.text, color: colors.text };

  const [uri, setUri] = useState(routeUrl);
  const [connecting, setConnecting] = useState(false);
  const actions = getActions();

  const onScan = () => navigation.navigate('QrcodeScannerPage');
  const onClear = () => setUri('');
  const onConnect = useCallback(async () => {
    if (!isWcUri(uri)) {return;}
    setConnecting(true);
    await actions.walletconnect.pair(uri);
    setConnecting(false);
  }, [uri, actions]);

  // Set uri to passed route prop
  useEffect(() => {
    setUri(route.params?.uri);
  }, [route]);

  return (
    <PageWrap>
      <Button
        title="SCAN"
        color={colors.primary}
        onPress={onScan}
      />
      <View style={{marginTop: 40, alignItems: 'flex-end'}}>
        <PressableOpacity style={styles.button} onPress={onClear} disabledOpacity={0.4}>
          <IonIcon name="close" color={colors.text} size={24} />
        </PressableOpacity>
      </View>
      <TextInput
          style={{...textStyles, fontSize: 14, maxHeight: 200}}
          placeholder="wc:..."
          multiline={true}
          numberOfLines={3}
          placeholderTextColor={colors.text}
          onChangeText={(value: string) => setUri(value)}
          defaultValue={uri || ''}
      />
      <View style={{marginBottom: 20, alignItems: 'center'}}>
        {connecting && (
          <StyledText>connecting...</StyledText>
        )}
      </View>
      <Button
        title="CONNECT"
        color={colors.primary}
        disabled={!isWcUri(uri) || connecting}
        onPress={onConnect}
      />
    </PageWrap>
  );
}

const styles = StyleSheet.create({
  text: {
    marginBottom: 40,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 18,
    textAlign: 'center',
    borderColor: 'gray',
    borderRadius: 5,
    borderWidth: 0.2,
    borderStyle: 'solid',
  },
  button: {
    ...commonStyles.iconButton,
  },
});

export default WalletConnectPage;

function isWcUri(uri: string | null): boolean {
  if (!uri) {return false;}
  if (uri.substring(0, 3) !== 'wc:') {return false;}
  return true;
}
