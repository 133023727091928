import React from 'react';
import {
    StyleSheet,
  TouchableOpacity,
} from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import commonStyles from '../styles';

const IconButton = ({icon, onPress, styleButton}: {icon: string, onPress: Function, styleButton?: any}) => {
    styleButton = styleButton || {};
    const { colors } = useTheme();

    const _onPress = () => {
        if (onPress) {onPress();}
    };

  return (
    <TouchableOpacity style={[styles.button, styleButton]} onPress={_onPress}>
        <IonIcon name={icon} color={colors.text} size={24} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    button: {
      ...commonStyles.iconButton,
    },
});

export default IconButton;
