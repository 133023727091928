
const NS = 'ganas';
const WALLET_STORAGE = `${NS}_wallet`;

// connected sites data
const CONNECTED_DAPPS_STORAGE = `${WALLET_STORAGE}_dapps`;

// profiles list
const PROFILES_STORAGE_ROOT = `${WALLET_STORAGE}_profiles`;

const PROFILES_STORAGE_COUNT = `${WALLET_STORAGE}_profiles_count`;
const PROFILES_STORAGE_CURRENT = `${WALLET_STORAGE}_profile_current`;

// SEEDS_STORAGE_ROOT<index> wallet seed
const SEEDS_STORAGE_ROOT = `${WALLET_STORAGE}_seeds`;

// how many derived keys in the wallet
const SEEDS_STORAGE_COUNT = `${WALLET_STORAGE}_seed_count`;

// | <index> for each external privateKey
const PK_STORAGE_ROOT = `${WALLET_STORAGE}_pk`;

// [<string>]
const ADDRESSES_STORAGE = `${WALLET_STORAGE}_addresses`;

const DEFAULT_HD_PATH = 'm/44\'/60\'/0\'/0';

export const DEFAULT_CHAIN_TYPE = 'testnet';
export const DEFAULT_PROFILE_CHAINS = {mainnet: {eip155: ['9001'], cosmos: [], monochain: 'eip155:9001'}, testnet:  {eip155: ['5'], cosmos: [], monochain: 'eip155:5'}};

export {
    NS,
    WALLET_STORAGE,
    CONNECTED_DAPPS_STORAGE,
    PROFILES_STORAGE_COUNT,
    PROFILES_STORAGE_ROOT,
    PROFILES_STORAGE_CURRENT,
    SEEDS_STORAGE_ROOT,
    SEEDS_STORAGE_COUNT,
    ADDRESSES_STORAGE,
    PK_STORAGE_ROOT,
    DEFAULT_HD_PATH,
};
