import chooseIdentityJson from './choose_identity.json';
import simpleStorage from './simple_storage.json';
import simpleStorageContract from './simple_storage_contract.json';
import cosmosAbi from './cosmos_abi.json';
import qrscan from './qrscan.json';
import walletConnectConfirm from './walletconnect_request_confirm.json';
import cosmosgov from './cosmos_gov.json';
import addChain from './add_chain.json';
import quasar from './quasar.json';
import quasarReverse from './quasar_evm_by_cosmos.json';
import web3Sheets from './web3_sheets.json';
import evmDapp from './evm_dapp.json';
import cwDapp from './cosmwasm_dapp.json';

export const MARKS_FACTORY_URL = 'https://mark.provable.dev';

// ?messageType=response&topic=walletconnect.session-chains&id=12&requiredChains=%7B"cosmos"%3A%5B"cosmoshub-4"%5D%2C"eip155"%3A%5B"3"%2C"4"%5D%7D&m=e
// ?messageType=request&topic=web3provider.setProviders&m=e
const CHOOSE_IDENTITY_HASH = 'QmYgR2DRJR9c9mniEnQgS7M6yDiMD2ZFM22Y1uHWWffLnY';
const walletconnectconfirm = 'QmZKAdjf9pDV5iFBoHaA4eFkEHS7F8ypi9cBsfaCA7Tfn4';
export const cosmosgovhash = 'QmfT4UZ7eqNQALA3NThA4k9eCagXS8EUFFm1yNYrz1Amm4';
export const quasarhash = 'QmTQcMKwPv94YxZ8Bacb29ggpQRvQngxDB7PYkQzG6jbVu';
export const addchainhash = 'QmVwExCeeHFhXV9RrVSJRPhEzZLjKRfyZ8rtNrwdzakxnD';
export const cosmosabihash = 'QmVvHjsJ3bdo7gWMbdwTFBPsuRUb1W6GDpjq3B5VHCAC4b';
export const simpleStorageHash = 'QmQ4yo97Vwbx5MbXmCerPkEH4amQ3eu8CG5eGfo9RwLYKU';
export const quasarReverseHash = 'QmQ2Fb48RW5qLamxqJuSYXQZYH4uvMndLkN9hcaQYDUisc';
export const web3SheetsHash = 'QmapwuHd8rnSfGG8Y7oug25MkHDE9rge7evTSk9Qhd8Uae';
export const evmDappHash = 'QmVTDQdzeU7aYP3M5zKGLuVuSKXWKSz8qr6NBxmb7M15Vc';
export const cwDappHash = 'QmSS6r1CnGdoR9XcgXQcqsENhWaNtyJvaSACzJZcZxoXKX';

export const MARKS_CACHE: any = {
    [CHOOSE_IDENTITY_HASH]: chooseIdentityJson,
    [simpleStorageHash]: simpleStorage,
    'Qmf2M2Jff9UfkSdtqQ6pGYSWnawqmaY9U11tuqdhTqN8F3': simpleStorageContract,
    [cosmosabihash]: cosmosAbi,
    'Qmc3xw5NESwtFvYc1M6D4edLJmbrsKqHCYGD8nkPESgfcu': qrscan,
    [walletconnectconfirm]: walletConnectConfirm,
    [cosmosgovhash]: cosmosgov,
    [addchainhash]: addChain,
    [quasarhash]: quasar,
    [quasarReverseHash]: quasarReverse,
    [evmDappHash]: evmDapp,
    [cwDappHash]: cwDapp,
    [web3SheetsHash]: web3Sheets,
};

export const MARKS = {
    chooseIdentity: getMark(CHOOSE_IDENTITY_HASH),
    walletconnectRequestAccept: getMark(walletconnectconfirm),
};

function getMark(hash: string) {
    return `${MARKS_FACTORY_URL}?ipfs=${hash}`;
}

export function saveMarkFromUrl(url: string) {

}
