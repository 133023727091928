import { initDirs, saveFile, getFile, shareFile } from './filesystem';

export async function extension () {
    const extensions = {
        label: 'filesystem',
        items: [
            {
                label: 'save',
                value: saveFile,
            },
            {
                label: 'get',
                value: getFile,
            },
            {
                label: 'share',
                value: shareFile,
            },

        ],
    };

    await initDirs();
    return {extensions};
}
