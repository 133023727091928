import React from 'react';
import {
    StyleSheet,
  TouchableOpacity,
} from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import Clipboard from '@react-native-clipboard/clipboard';
import commonStyles from '../styles';

const ClipboardCopyButton = (props: any) => {
    const {value} = props;
    const { colors } = useTheme();

    const copyToClipboard = () => {
        if (value) {Clipboard.setString(value);}
    };

    const styleButton = props.styleButton || {};
    const styleIcon = props.styleIcon || {};

  return (
    <TouchableOpacity style={[styles.button, styleButton]} onPress={copyToClipboard}>
        <IonIcon style={[ styleIcon]} name="md-copy" color={colors.text} size={24} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    button: {
      ...commonStyles.iconButton,
    },
});

export default ClipboardCopyButton;
