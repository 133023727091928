export enum ACCESSIBLE {
    WHEN_UNLOCKED = 'AccessibleWhenUnlocked',
    AFTER_FIRST_UNLOCK = 'AccessibleAfterFirstUnlock',
    ALWAYS = 'AccessibleAlways',
    WHEN_PASSCODE_SET_THIS_DEVICE_ONLY = 'AccessibleWhenPasscodeSetThisDeviceOnly',
    WHEN_UNLOCKED_THIS_DEVICE_ONLY = 'AccessibleWhenUnlockedThisDeviceOnly',
    AFTER_FIRST_UNLOCK_THIS_DEVICE_ONLY = 'AccessibleAfterFirstUnlockThisDeviceOnly',
}

export enum ACCESS_CONTROL {
    USER_PRESENCE = 'UserPresence',
    BIOMETRY_ANY = 'BiometryAny',
    BIOMETRY_CURRENT_SET = 'BiometryCurrentSet',
    DEVICE_PASSCODE = 'DevicePasscode',
    APPLICATION_PASSWORD = 'ApplicationPassword',
    BIOMETRY_ANY_OR_DEVICE_PASSCODE = 'BiometryAnyOrDevicePasscode',
    BIOMETRY_CURRENT_SET_OR_DEVICE_PASSCODE = 'BiometryCurrentSetOrDevicePasscode',
}

export enum AUTHENTICATION_TYPE {
    DEVICE_PASSCODE_OR_BIOMETRICS = 'AuthenticationWithBiometricsDevicePasscode',
    BIOMETRICS = 'AuthenticationWithBiometrics',
}

export enum SECURITY_LEVEL {
    SECURE_SOFTWARE,
    SECURE_HARDWARE,
    ANY,
}

export enum BIOMETRY_TYPE {
    TOUCH_ID = 'TouchID',
    FACE_ID = 'FaceID',
    FINGERPRINT = 'Fingerprint',
    FACE = 'Face',
    IRIS = 'Iris',
}

export enum STORAGE_TYPE {
    FB = 'FacebookConceal',
    AES = 'KeystoreAESCBC',
    RSA = 'KeystoreRSAECB',
    KC = 'keychain',
}

export enum SECURITY_RULES {
    NONE = 'none',
    AUTOMATIC_UPGRADE = 'automaticUpgradeToMoreSecuredStorage',
}

export function setGenericPassword(key: string, seed: string, options: any) {
    return localStorage.setItem('keychain_' + key, seed);
}

export function getGenericPassword({service}: any) {
    return localStorage.getItem('keychain_' + service);
}
