import React from 'react';
import {
    StyleSheet,
  TouchableOpacity,
} from 'react-native';
import StyledText from './StyledText';
import commonStyles from '../styles';

const TextButton = ({title, onPress, styleButton, styleText}: {title: string, onPress: Function, styleButton?: any, styleText?: any}) => {
    styleButton = styleButton || {};
    styleText = styleText || {};

    const _onPress = () => {
        if (onPress) {onPress();}
    };

    const _styleText = {
        fontSize: 18,
    };

  return (
    <TouchableOpacity style={[styles.button, styleButton]} onPress={_onPress}>
        <StyledText style={[_styleText, styleText]}>{title}</StyledText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    button: {
      padding: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: commonStyles.roundness.radius,
      borderColor: 'gray',
      alignItems: 'center',
      justifyContent: 'center',
    },
});

export default TextButton;
