import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  Platform,
  View,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import {getRootDir, getDocs} from '../services/filesystem/filesystem';
import {ReadDirItem} from '../services/filesystem/types';
import Menu from '../components/Menu';
import {MenuItem} from '../services/menus/types';

export default function FileSystemPage(props: any) {
    let {navigation, route} = props;
    let dirname = route.params?.dirname;
    if (!dirname) {
        dirname = getRootDir();
    }

    return (
        <FileSystem dirname={dirname} navigation={navigation} />
    );
}

function FileSystem (props: any) {
    const {dirname, navigation} = props;
    const [docs, setDocs] = useState<ReadDirItem[]>([]);

    const { colors } = useTheme();
    let textStyles = { ...styles.text, color: colors.text };

    useEffect(() => {
        const _setDocs = async function () {
            const docs = await getDocs(dirname);
            setDocs(docs);
        };
        _setDocs();
    }, [dirname]);

    const menu: MenuItem = {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'files',
            submenu: buildMenu(docs),
        },
    };

    return (
        <View style={{flex: 1}}>
            <Menu menu={menu} value={menu} path={''} index={0}/>
        </View>
    );
}

function buildMenu(docs: ReadDirItem[]): MenuItem[] {
    return docs.map((doc) => {
        const isDir = doc.isDirectory();
        let btnAction;
        if (isDir) {
            btnAction = {type: 'function', subtype: 'navigation.push', args: ['FileSystemPage', {dirname: doc.path}]};
        } else {
            btnAction = {type: 'function', subtype: 'filesystem.share', args: [{url: 'file://' + doc.path}]};
        }

        return {
            type: 'Item',
            props: {
                hasNavArrow: isDir,
                title: doc.name,
                onPress: btnAction,
            },
        };
    });
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerText: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 50,
    paddingLeft: 30,
  },
  text: {

  },
});
