import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {
    View,
    Animated,
    StyleSheet,
    Platform,
    Easing,
    Dimensions,
    TouchableOpacity,
} from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import SortableList from '@ark-us/react-native-sortable-list';
import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useTheme } from '@react-navigation/native';
import type { Routes } from './Routes';
import StyledText from '../components/StyledText';
import {MenuItemWrap} from '../components/Menu';
import {useActions} from '../services/plugems/plugems';
import AddUrl from '../components/AddUrl';
import {getDataFromPath, DEFAULT_MENU_PATH} from '../services/menus/utils';
import commonStyles from '../styles';
import menuJson, {USER_BOOKMARKS} from '../services/menus/menu';
import {MenuItem} from '../services/menus/types';

const window = Dimensions.get('window');
const DOUBLE_PRESS_DELAY = 500; // ms

function Row(props: any) {
  const {key, index, data, disabled, active, actions, path} = props;
  const { colors } = useTheme();

  const activeAnim = useRef(new Animated.Value(0));
  const style = useMemo(
    () => ({
      ...Platform.select({
        ios: {
          transform: [
            {
              scale: activeAnim.current.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1.07],
              }),
            },
          ],
          shadowRadius: activeAnim.current.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 10],
          }),
        },

        android: {
          transform: [
            {
              scale: activeAnim.current.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1.07],
              }),
            },
          ],
          elevation: activeAnim.current.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 6],
          }),
        },
      }),
    }),
    [],
  );

  useEffect(() => {
    Animated.timing(activeAnim.current, {
      duration: 300,
      easing: Easing.bounce,
      toValue: Number(active),
      useNativeDriver: true,
    }).start();
  }, [active]);

  const onEditRow = () => {

  };

  const onDeleteRow = () => {

  };

  const item = <MenuItemWrap menu={data} value={data} actions={actions} path={path} index={index} descendantsCount={undefined} />;

    const rowStyle = {
        ...styles.row,
        backgroundColor: colors.border,
        width: '100%',
    };

    let editableButtons = <React.Fragment />;
    if (data.editable && data.isEditable) {
      rowStyle.paddingLeft = 50;
      const bstyle = {marginRight: 10};
      const cstyle: any = {
        float: 'left',
      };
      editableButtons = (
        // <View style={cstyle}>
        <React.Fragment>
            <TouchableOpacity style={[commonStyles.iconButton, bstyle]} onPress={onEditRow}>
                <IonIcon name="pencil" color={colors.text} size={24} />
            </TouchableOpacity>
            <TouchableOpacity style={[commonStyles.iconButton, bstyle]} onPress={onDeleteRow}>
                <IonIcon name="close" color={colors.text} size={24} />
            </TouchableOpacity>
        </React.Fragment>
        // </View>
      );
    }

  return (
    <Animated.View key={index} style={[rowStyle, style]}>
        {editableButtons}
        {item}
    </Animated.View>
  );
}

type Props = BottomTabScreenProps<Routes, 'MenuPage'>;
export default function MenuScreen({ navigation, route }: Props): React.ReactElement {
    const path = route.params?.path || DEFAULT_MENU_PATH;
    const actions = useActions();
    const { colors } = useTheme();
    const [bookmarkData, setBookmarkData] = React.useState([]);

    React.useEffect(() => {
      async function init() {
        if (!actions?.bookmarks) {return;}
        const bookmarks = await actions.bookmarks.get();
        if (bookmarks) {setBookmarkData(bookmarks);}
      }
      init();
      // we need to get the data after actions are initialized
    }, [actions]);

    React.useEffect(() => {
      if (!actions?.events?.plugems?.bookmarks) {return;}
      const listener = (bookmarkData: any) => {
        setBookmarkData(bookmarkData);
      };
      actions.events.plugems.bookmarks.onBookmarks(listener);
      return () => actions.events.plugems.bookmarks.offBookmarks(listener);
    });

    if (!actions?.navigation) {return (<StyledText>loading...</StyledText>);}

    const containerStyle = {
        ...styles.container,
        backgroundColor: colors.background,
    };

    const fullData: MenuItem = menuJson;
    const bookmarkIndex = (fullData.props.submenu || []).findIndex((item: any) => item.props.title === USER_BOOKMARKS);
    if (bookmarkIndex >  -1) {
      // @ts-ignore
      fullData.props.submenu[bookmarkIndex].props.submenu = bookmarkData;
    }

    let {item, parentNames} = getDataFromPath(fullData, path);
    const parentName = parentNames[parentNames.length - 1];

  return (
    <View style={containerStyle}>
      {parentName === USER_BOOKMARKS && (
        <AddUrl navigation={navigation} path={path} />
      )}
      <MenuItemDraggable item={item} actions={actions} parentName={parentName} path={path} />
    </View>
  );
}

function MenuItemDraggable({item, actions, parentName, path}: any) {
  const [editableKey, setEditableKey] = useState<number | null>(null);
  const [lastPress, setLastPress] = useState(new Date().getTime());
  const [submenu, setSubmenu] = React.useState(item.props.submenu || []);

  React.useEffect(() => {
    async function init() {
        if (!item.itemUrl) {return;}
        const items = await fetch(item.itemUrl);
        const itemJson = await items.json().catch(console.error);
        if (!itemJson) {return;}
        setSubmenu(itemJson.props.submenu);
    }
    init();
  }, [item]);

  React.useEffect(() => {
      setSubmenu(item.props.submenu);
  }, [item]);

  const renderRow = useCallback(({key, index, data, disabled, active}: any) => {
    return <Row index={index} disabled={disabled} data={data} active={active} actions={actions} path={path}/>;
  }, [actions, path]);

  const onPressRow = useCallback((key: string) => {
    var delta = new Date().getTime() - lastPress;
    if (delta < DOUBLE_PRESS_DELAY) {
      const _key = parseInt(key, 10);
      if (editableKey === _key) {setEditableKey(null);}
      else {setEditableKey(_key);}
    }
    setLastPress(new Date().getTime());
  }, [lastPress, editableKey]);


  const subData = submenu.map((d: any) => {
    d.editable = false;
    return d;
  });
  if (editableKey !== null) {subData[editableKey].editable = true;}

  return (
    <SortableList
      style={styles.list}
      contentContainerStyle={styles.contentContainer}
      innerContainerStyle={styles.innerContainer}
      data={subData}
      renderRow={renderRow}
      onPressRow={onPressRow}
      sortingEnabled={parentName === USER_BOOKMARKS}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    flex: 1,
    width: '100%',
    // padding: 0,
    // margin: 0,
  },
  contentContainer: {
    width: '100%',
    paddingBottom: 30,
    // padding: 0,
    // margin: 0,
  },
  innerContainer: {
    // height: 60,
    // padding: 0,
    // margin: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 30,
    margin: 0,
    flex: 1,
    borderRadius: commonStyles.roundness.radius,
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0,0,0,0.2)',
        shadowOpacity: 1,
        shadowOffset: {height: 2, width: 2},
        shadowRadius: 2,
      },
      android: {
        elevation: 0,
      },
    }),
  },
});
