/**
 * @format
 * @flow
*/

import {Component} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  ScrollView,
  View,
  StatusBar,
  Button,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import ClipboardCopyButton from '../ClipboardCopy';
import * as wallet from '../../services/wallet/accounts';
import StyledText from '../StyledText';
import commonStyles from '../../styles';

export default function(props: any) {
    const theme = useTheme();
    return <Wallet {...props} theme={theme} />;
}

class Wallet extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            mnemonic : '',
            showMnemonic: false,
            errorMessage: '',
        };

        this.setMnemonic = this.setMnemonic.bind(this);
        this.generateMnemonic = this.generateMnemonic.bind(this);
        this.importWallet = this.importWallet.bind(this);
    }

    setMnemonic(mnemonic: string) {
        this.setState({mnemonic});
    }

    async generateMnemonic() {
        if (this.state.accounts && this.state.accounts.length > 1) {return;}
        const mnemonic = await wallet.generateMnemonic();
        this.setMnemonic(mnemonic);
        this.setState({showMnemonic: true});
    }

    async importWallet() {
        if (!this.state.mnemonic) {return;}
        const profile = await wallet.generateProfile(this.state.mnemonic);
        this.setMnemonic('');
        // remove this route before going to profile page
        // so we can go back to the profiles page
        this.props.navigation.pop();
        this.props.navigation.navigate('ProfilePage', {value: profile});
    }

    render() {
        const { theme } = this.props;
        const { colors } = theme;

    return (
        <>
        <StatusBar barStyle="dark-content" />
        <SafeAreaView>
            <ScrollView
                contentInsetAdjustmentBehavior="automatic"
                style={{backgroundColor: colors.background}}>
            <View style={{backgroundColor: colors.background}}>
            {this.state.errorMessage !== '' && (
                <View style={{flex: 1, width: '100%', display: 'flex'}}>
                <StyledText style={{color: 'rgb(255, 121, 198)', textAlign: 'center'}}>{this.state.errorMessage}</StyledText>
                <TouchableOpacity style={commonStyles.iconButton} onPress={() => this.setState({errorMessage: ''})}>
                    <IonIcon name="close" color={colors.text} size={24} />
                </TouchableOpacity>
                </View>
            )}
            {(!this.state.accounts || this.state.accounts.length === 0) && (
                <View style={{flex: 1, width: '100%'}}>
                <View style={styles.sectionContainer}>
                    <Button title="Generate mnemonic" color={colors.primary}
                    onPress={() => this.generateMnemonic()}
                    />
                </View>
                <View style={{marginTop: 40, alignItems: 'flex-end'}}>
                    {this.state.showMnemonic && this.state.mnemonic !== '' && (
                    <ClipboardCopyButton value={this.state.mnemonic} />
                    )}
                </View>
                <TextInput
                    style={{
                        ...styles.seedInput,
                        borderColor: colors.border,
                        color: colors.text,
                        backgroundColor: colors.input,
                    }}
                    multiline={true}
                    numberOfLines={4}
                    placeholder="or paste existent mnemonic ..."
                    placeholderTextColor={colors.placeholder}
                    onChangeText={this.setMnemonic}
                    defaultValue={this.state.mnemonic}
                     />
                    <Button title="Create Wallet" color={colors.primary}
                    onPress={() => this.importWallet()}
                    />
                </View>
            )}
            </View>
            </ScrollView>
        </SafeAreaView>
        </>
        );
    }
}

const styles = StyleSheet.create({
    engine: {
        position: 'absolute',
        right: 0,
    },
    sectionContainer: {
        marginTop: 64,
        paddingHorizontal: 24,
    },
    sectionTitle: {
        fontSize: 24,
        fontWeight: '600',
    },
    sectionDescription: {
        marginTop: 8,
        fontSize: 18,
        fontWeight: '400',
    },
    highlight: {
        fontWeight: '700',
    },
    seedInput: {
        height: 40,
        borderRadius: 3,
        flex: 1,
        borderWidth: 1,
        marginRight: 8,
        paddingLeft: 8,
        textAlign: 'center',
    },
});
