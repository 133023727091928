/**
 * When compiling for web, it will search for index.js on root/src.
 */

import './shim.web.js';
import 'react-native-gesture-handler';
import 'react-native-reanimated';
import {createRoot} from 'react-dom/client';
import App from './App';

// RNVI integration for web
import './icons';
import './fonts';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);

