import * as React from 'react';
import * as storage from '../storage';
import {MARKS} from '../marks/marks';
import {MenuItem} from './types';
import nativeApps from './nativeApps';
import web3Apps from './web3Apps';
import proofApps from './proofApps';
import markApps from './markApps';
import settingsApps from './settings';

export const USER_BOOKMARKS = 'my ganas';

const menuJson: MenuItem = {type: 'Item', props: {
    hasNavArrow: true,
    title: 'menu',
    submenu: [
    // {
    //     type: 'Header',
    //     props: {headerStyle:{marginTop:15}},
    // },
    {
        type: 'Item',
        props: {
            hasNavArrow: false,
            title: 'choose identity',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['BrowserPage', {
                url: MARKS.chooseIdentity + '&messageType=request&topic=wallet.setCurrentProfile&m=p',
            }]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: false,
            title: 'wallet',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['ProfilesPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: false,
            title: 'wallet connect',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['WalletConnectPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'web3',
            submenu: web3Apps,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'proofs',
            submenu: proofApps,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'marks',
            submenu: markApps,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'dynamic demos',
            submenu: [
            ],
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
        itemUrl: 'https://raw.githubusercontent.com/ark-us/ganas-releases/main/ganas-1.0.17/demos.json',
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'native apps',
            submenu: nativeApps,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: USER_BOOKMARKS,
            submenu: [
            ],
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'settings',
            submenu: settingsApps,
            onPress: {type: 'function', subtype: 'navigation.push', args: ['MenuPage', {}]},
        },
    },
]}};

export default menuJson;

const BOOKMARKS_ROOT = 'bookmarks';

export async function getBookmarks() {
    const data = await storage.getDataObject(BOOKMARKS_ROOT);
    return data || [];
}

export async function setBookmarks(data: MenuItem[]) {
    await storage.storeData(BOOKMARKS_ROOT, JSON.stringify(data));
    dispatcher && dispatcher('Bookmarks')(data);
}

export async function addBookmark(bookmark: MenuItem, path: string) {
    const data = await getBookmarks();
    let subData = data;
    if (path.length > 0) {
        const indexes = path.split(',');
        indexes.forEach((index: string) => {
            const ind = parseInt(index.trim(), 10);
            subData = subData[ind].props.submenu;
        });
    }
    if (!subData) {throw new Error('Adding bookmark in a non-folder');}
    subData.push(bookmark);
    await setBookmarks(data);
    dispatcher && dispatcher('Bookmarks')(data);
    dispatcher && dispatcher('Bookmark')(bookmark);
}

export async function removeBookmark(index: number) {
    const data = await getBookmarks();
    data.splice(index, 1);
    await setBookmarks(data);
    dispatcher && dispatcher('Bookmarks')(data);
}

let dispatcher: Function;
export async function extension (_dispatcher: Function) {
    dispatcher = _dispatcher('bookmarks');
    const extensions = {
        label: 'bookmarks',
        items: [
            {
                label: 'get',
                value: getBookmarks,
            },
            {
                label: 'add',
                value: addBookmark,
            },
            {
                label: 'remove',
                value: removeBookmark,
            },
        ],
        events: [
            'Bookmarks',
            'Bookmark',
        ],
    };

    return {extensions};
}
