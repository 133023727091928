import * as React from 'react';
import { StyleSheet, TextInput, View, TouchableOpacity } from 'react-native';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import {useActions} from '../services/plugems/plugems';
import commonStyles from '../styles';
import {GanasThemeColors} from '../theme/types';

export default function AddUrl({navigation, path}: any) {
  let colors: GanasThemeColors;
  // @ts-ignore
  ({colors} = useTheme());
  const actions = useActions();
  const [url, setUrl] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [showName, setShowName] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!actions?.events?.plugems?.browser) {return;}
    const listener = (url: string) => {
        setUrl(url);
    };
    actions.events.plugems.browser.onCurrentUrl(listener);
    return () => actions.events.plugems.browser.offCurrentUrl(listener);
  });

  React.useEffect(() => {
    if (!actions?.browser) {return;}
    const currentUrl = actions.browser.getCurrentUrl();
    if (currentUrl) {setUrl(currentUrl);}
  }, [actions]);

  const onNavigate = React.useCallback(() => {
    if (!url) {return;}
    navigation.navigate('BrowserPage', {url});
  }, [url, navigation]);

  const onExportToChat = React.useCallback(() => {
    if (!actions?.chat) {return;}
    if (!url) {return;}
    const params = actions.chat.getCurrentChat();
    navigation.navigate('ChatPage', {...params, message: {text: url}});
  }, [actions, url, navigation]);

  const onUrlAdd = () => {
    setShowName(true);
  };

  const onNameClear = React.useCallback(() => {
    setShowName(false);
    setName('');
  }, []);

  const onBookmarkAdd = React.useCallback(() => {
    if (!url && !name) {return;}
    const hasNavArrow = (url && url.includes('http')) ? false : true;
    const title = name || url;
    const submenu = hasNavArrow ? [] : undefined;
    const onPress = hasNavArrow
      ? ({
        type: 'function',
        subtype: 'navigation.push',
        args: ['MenuPage', {}],
      })
      : ({
        type: 'function',
        subtype: 'navigation.navigate',
        args: ['BrowserPage', {url}],
      });
    const item = {
      type: 'Item',
      props: { hasNavArrow, title, onPress, submenu },
      isEditable: true,
    };

    // use path relative to my ganas folder
    actions.bookmarks.add(item, '');
    setName('');
    setShowName(false);
    setUrl('');
  }, [url, name, actions]);

  return (
    <View style={{display: 'flex', marginTop: 1, width: '100%'}}>
      <View style={{width: '100%', flexDirection: 'row'}}>
        <View style={{flex: 6, height: 60}}>
          <TextInput
            style={{
              ...commonStyles.textStyle,
              ...styles.textInput,
              borderColor: colors.border,
              color: colors.text,
            }}
            value={url}
            placeholder="url ..."
            placeholderTextColor={colors.placeholder}
            onChangeText={setUrl}
           />
        </View>
        {!showName && (
          <TouchableOpacity style={styles.addButton} onPress={onUrlAdd}>
            <IonIcon name="add" color={colors.text} size={24} />
          </TouchableOpacity>
        )}
      </View>
      {showName && (
        <View style={{width: '100%', flexDirection: 'row'}}>
          <View style={{flex: 6, height: 60}}>
            <TextInput
              style={{
                ...commonStyles.textStyle,
                ...styles.textInput,
                borderColor: colors.border,
                color: colors.text,
              }}
              value={name}
              placeholder="name ..."
              placeholderTextColor={colors.placeholder}
              onChangeText={setName}
             />
          </View>
         </View>
      )}
      {showName && (
        <View style={{flexDirection: 'row', marginTop: 10}}>
          <TouchableOpacity style={{...styles.addButton2, flex: 1}} onPress={onNameClear}>
            <IonIcon name="close" color={colors.text} size={24} />
          </TouchableOpacity>
          <TouchableOpacity style={{...styles.addButton2, flex: 1}} onPress={onBookmarkAdd}>
            <IonIcon name="checkmark" color={colors.text} size={24} />
          </TouchableOpacity>
        </View>
      )}
      <View style={{flexDirection: 'row'}}>
          <TouchableOpacity disabled={!url} style={{...styles.navigateButton, flex: 1}} onPress={onNavigate}>
            <IonIcon name="arrow-forward" color={colors.text} size={24} />
          </TouchableOpacity>
          <TouchableOpacity disabled={!url} style={{...styles.navigateButton, flex: 1}} onPress={onExportToChat}>
            <IonIcon name="chatbubble-ellipses" color={colors.text} size={24} />
          </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    borderRadius: 5,
    flex: 1,
    borderWidth: 1,
    marginRight: 8,
    paddingLeft: 8,
    textAlign: 'center',
  },
  addButton: {
    ...commonStyles.iconButton,
    flex: 1,
  },
  addButton2: {
    ...commonStyles.iconButton,
  },
  navigateButton: {
    ...commonStyles.iconButton,
    float: 'right',
    marginTop: 20,
  },
});
