import * as ethermint from '@evmos/proto/dist/proto/ethermint/evm/v1/tx';
import * as queries from '@evmos/proto/dist/proto/ethermint/evm/v1/query';
import * as any from '@evmos/proto/dist/proto/google/protobuf/any';

// console.log('ethermint', ethermint);

// console.log('MsgEthereumTx', ethermint.ethermint.evm.v1.MsgEthereumTx);

const MsgEthereumTx = ethermint.ethermint.evm.v1.MsgEthereumTx;
const LegacyTx = ethermint.ethermint.evm.v1.LegacyTx;
const DynamicFeeTx = ethermint.ethermint.evm.v1.DynamicFeeTx;
const Any = any.google.protobuf.Any;
const EthCall = queries.ethermint.evm.v1.EthCallRequest;
const MsgEthereumTxResponse =  ethermint.ethermint.evm.v1.MsgEthereumTxResponse;

// fromObject
// toObject
// serialize
// deserialize

export function isEthermintMessage (typeUrl: string): boolean {
    return typeUrl.includes("MsgEthereumTx");
}

// ethermint.evm.v1.DynamicFeeTx
// ethermint.evm.v1.LegacyTx

export function encodeMsgEthereumTx (value: any)  {
    const {data, from} = value;
    const txData = LegacyTx.fromObject({data})
    const msgData = {
        data: {type_url: "ethermint.evm.v1.LegacyTx", data: txData},
        from: value.from,
    }
    console.log('---encodeMsgEthereumTx', msgData);
    const instance = MsgEthereumTx.fromObject(msgData);
    return instance.serializeBinary();
}

// /proto/ethermint/evm/v1/tx
// ethermint.ethermint.evm.v1.MsgEthereumTx

export function encodeMsgEthereumTxAny (typeUrl: string, valueObj: any) {
    const value = encodeMsgEthereumTx(valueObj);
    return new Any({type_url: typeUrl, value});
}

export function EthCallEncode(value: any) {
    const {args, gasCap} = value;
    const data = new EthCall({args, gas_cap: gasCap});
    return data.serializeBinary();
}

// export function EthCallDecode(value: any) {
//     const r = new MsgEthereumTxResponse(value);
//     return r.
// }
