import {GanasTheme} from './types';

const DarkTheme: GanasTheme = {
  dark: true,
  colors: {
    primary: 'rgb(26, 115, 203)',
    background: 'rgb(1, 1, 1)',
    card: 'rgb(18, 18, 18)',
    text: 'rgb(229, 229, 231)',
    placeholder: 'rgba(186,194,201,0.8)',
    border: 'rgb(39, 39, 41)',
    notification: 'rgb(255, 69, 58)',
    input: '#333',
    icon: '#423E4B',
    icon_active: '#eee', // #423e50
    icon_inactive: '#808080',
  },
};

export default DarkTheme;
