import * as filesystem from './filesystem/filesystem';
import {MARKS_CACHE} from './marks/marks';

export function getIpfsDir(): string {
    return filesystem.getCacheDir() + '/ipfs';
}

export async function setIpfsFile(ipfsHash: string, data: any) {
    const filepath = getIpfsDir() + '/' + ipfsHash;
    return filesystem._saveFile(filepath, data, 'utf8');
}

export async function getIpfsFile(ipfsHash: string) {
    if (MARKS_CACHE[ipfsHash]) {
        return JSON.stringify(MARKS_CACHE[ipfsHash]);
    }

    const filepath = getIpfsDir() + '/' + ipfsHash;
    let exists = await filesystem.Filesystem.exists(filepath);
    if (!exists) {return null;}
    return filesystem.getFile(filepath, 'utf8');
}

export async function initDirs() {
    await filesystem.createDirIfNotExists(getIpfsDir());
}

export async function extension () {
    const extensions = {
        label: 'ipfs',
        items: [
            {
                label: 'cache',
                value: setIpfsFile,
            },
            {
                label: 'get-cache',
                value: getIpfsFile,
            },
        ],
    };

    await initDirs();
    return {extensions};
}
