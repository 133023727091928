// @ts-nocheck
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */

import React, { useEffect, useState } from 'react';
import {
  useColorScheme,
  View,
  Image,
  Text,
} from 'react-native';
import DarkTheme from './theme/dark';
import styles from './styles';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {navigationRef} from './services/navigation';
import {NavigationContainer} from '@react-navigation/native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import IonIcon from 'react-native-vector-icons/Ionicons';
import MenuScreen from './views/Menu';
import BrowserScreen from './views/Browser';
import AboutPage from './pages/AboutPage';
import PermissionsPage from './pages/Permissions';
import BlockTreeScreen from './views/BlockTreeScreen';
import BlockTreePage from './pages/BlockTreePage';
import ChatPage, {ChatPageByHash} from './components/chat/Chat';
import ContactsPage from './components/chat/Contacts';
import NewContact from './components/chat/NewContact';
import ProfilesPage from './components/wallet/Profiles';
import ProfileNewPage from './components/wallet/ProfileNew';
import ProfilePage from './components/wallet/Profile';
import CameraScreen from './pages/Camera';
import MediaScreen from './pages/MediaPage';
import QrcodePage from './pages/QrcodePage';
import QrcodeScannerPage from './pages/QrcodeScannerPage';
import FileSystemPage from './pages/FileSystem';
import SensorsPage from './pages/SensorsPage';
import WalletConnectPage from './pages/WalletConnect';
import TorchPage from './pages/TorchPage';
import { useActions } from './services/plugems/plugems';
import {Profile} from './services/wallet/types';
import ganasImage from './assets/icons/ganas.png';
import ganasImageW from './assets/icons/ganas-w.png';
import blockTreeImage from './assets/icons/blocktree.png';
import blockTreeImageW from './assets/icons/blocktree-w.png';

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

const tabIconSize = 35;
const tabIconMargin = 18;
const tabIconPaddingBottom = 8;
const tabIconSeparator = 6;
const tabIconStyle: any = {
  position: 'absolute',
  padding: 0,
  paddingBottom: tabIconPaddingBottom,
  height: tabIconSize,
  index: 10000,
};

const tabScreenOptions = {
  title: '',
  tabBarShowLabel: false,
  headerShown: false,
  tabBarStyle: {
    position: 'absolute',
    borderTopColor: 'rgba(0, 0, 0, 0)',
    borderWidth: 0,
    borderTopWidth: 0,
    height: 30,
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    marginLeft: 30,
    marginRight: 30,
    ...styles.textStyles,
  },
  // tabBarBackground: () => (
  //   <View
  //     style={[StyleSheet.absoluteFill, {
  //       backgroundColor: 'rgba(0, 0, 0, 0.2)',
  //       // height: 30
  //     }]}
  //   />
  // ),
  tabBarBackground: () => (
    <View
      style={[
        {
          backgroundColor: 'transparent',
        },
      ]}
    />
  ),
};

const stackScreenOptions = {
  // title: '',
  headerBackButtonMenuEnabled: false,
  headerLargeTitle: false,
  statusBarTranslucent: true,
  headerBackTitleStyle: {
    ...styles.textStyles,
  },
  headerLargeTitleStyle: {
    ...styles.textStyles,
  },
  headerTitleStyle: {
    ...styles.textStyles,
  },
  // headerShown: false,
  // headerTransparent: true,
  // headerStyle: {},
  // headerBackground: () => (
  //   <View
  //     style={[StyleSheet.absoluteFill, {
  //       backgroundColor: 'rgba(0, 0, 0, 0)',
  //       height: 30
  //     }]}
  //   />
  // ),
  headerStyle: {
    ...styles.textStyles,
    // backgroundColor: 'rgba(0, 0, 0, 0)',
    // height: 30,
  },
};

function MenuStack() {
  return (
    <Stack.Navigator screenOptions={{
      ...stackScreenOptions,
      title: 'ganas',
    }}>
      <Stack.Screen name="MenuPage" component={MenuScreen} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'ganas' })}/>
      <Stack.Screen name="BrowserPage" component={BrowserScreen} options={{title: '', headerShown: false}}/>
      <Stack.Screen name="AboutPage" component={AboutPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'about' })}/>
      <Stack.Screen name="PermissionsPage" component={PermissionsPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'permissions' })}/>
      <Stack.Screen name="CameraPage" component={CameraScreen} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'camera' })}/>
      <Stack.Screen name="MediaPage" component={MediaScreen} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'media' })}/>
      <Stack.Screen name="ProfilePage" component={ProfilePage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'profile' })}/>
      <Stack.Screen name="ProfilesPage" component={ProfilesPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'profiles' })}/>
      <Stack.Screen name="ProfileNewPage" component={ProfileNewPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'new profile' })}/>
      <Stack.Screen name="QrcodePage" component={QrcodePage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'qrcode' })}/>
      <Stack.Screen name="QrcodeScannerPage" component={QrcodeScannerPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'qrcode scan' })}/>
      <Stack.Screen name="FileSystemPage" component={FileSystemPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'files' })}/>
      <Stack.Screen name="SensorsPage" component={SensorsPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'sensors' })}/>
      <Stack.Screen name="TorchPage" component={TorchPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'torch' })}/>
      <Stack.Screen name="WalletConnectPage" component={WalletConnectPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'wallet connect' })}/>
    </Stack.Navigator>
  );
}

function ChatStack() {
  return (
    <Stack.Navigator screenOptions={{
      ...stackScreenOptions,
      title: 'menu',
    }}>
      <Stack.Screen name="ContactsPage" component={ContactsPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'contacts' })}/>
      <Stack.Screen name="ChatPage" component={ChatPage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'chat', headerTitleStyle: {fontSize: 16} })}/>
      <Stack.Screen name="ChatPageByHash" component={ChatPageByHash} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'chat', headerTitleStyle: {fontSize: 16} })}/>
      <Stack.Screen name="NewContact" component={NewContact} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'new contact' })}/>
    </Stack.Navigator>
  );
}

function BlockTreeStack() {
  return (
    <Stack.Navigator screenOptions={{
      ...stackScreenOptions,
      title: 'menu',
    }}>
      <Stack.Screen name="BlockTreeScreen" component={BlockTreeScreen} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'blocks' })}/>
      <Stack.Screen name="BlockTreePage" component={BlockTreePage} options={({ route }: any) => ({ title: route?.params?.screenTitle || 'blocks' })}/>
    </Stack.Navigator>
  );
}

function App() {
  const scheme = useColorScheme();
  // const theme = scheme === 'dark' ? DarkTheme : LightTheme;
  const theme = DarkTheme;

  const _tabScreenOptions = {
    ...tabScreenOptions,
    sceneContainerStyle: {
      backgroundColor: 'transparent', // theme.colors.background,
    },
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
    <NavigationContainer ref={navigationRef} theme={theme}>
      <Tab.Navigator style={{backgroundColor: 'transparent'}}
        initialRouteName="MenuPage"
        screenOptions={({ route }: {route: any}) => ({
          tabBarIcon: ({ focused, color, size }: {focused: boolean, color: string, size: number}) => {
            let iconName;
            if (route.name === 'MenuStack') {
              if (color === theme.colors.icon_inactive) {
                return <Image source={ganasImage} style={{width: size, height: size}}></Image>
              }
              else {
                return <Image source={ganasImageW} style={{width: size, height: size}}></Image>
              }
            }
            else if (route.name === 'BlockTreeStack') {
              if (color === theme.colors.icon_inactive) {
                return <Image source={blockTreeImage} style={{width: size, height: size}}></Image>
              }
              else {
                return <Image source={blockTreeImageW} style={{width: size, height: size}}></Image>
              }
            }
            else if (route.name === 'ExportPage') {
              iconName = focused ? 'log-out' : 'log-out-outline';
            }
            else if (route.name === 'ChatStack') {
              iconName = focused ? 'chatbubble-ellipses' : 'chatbubble-ellipses-outline';
            }
            else {
              iconName = focused ? 'add-circle' : 'add-circle-outline';
            }

            // You can return any component that you like here!
            return <IonIcon name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: theme.colors.icon_active,
          tabBarInactiveTintColor: theme.colors.icon_inactive,
          tabBarIconStyle: {
            backgroundColor: 'transparent',
          },
          style: {
            elevation: 0,
            backgroundColor: 'transparent',
          },
        })}
      >
        <Tab.Screen name="MenuStack" component={MenuStack} options={{
          ..._tabScreenOptions,
          tabBarItemStyle: {
            ...tabIconStyle,
            left: 0,
            marginLeft: tabIconMargin,
        }}} />
        <Tab.Screen name="ExportPage" component={MenuScreen} initialParams={{ path: '8', screenTitle: 'my ganas' }} options={{
          ..._tabScreenOptions,
          headerShown: true,
          title: 'my ganas',
          headerStyle: {
            ...styles.textStyles,
          },
          tabBarItemStyle: {
            ...tabIconStyle,
            left: 0,
            marginLeft: tabIconMargin + tabIconSeparator + tabIconSize,
        }}} />
        <Tab.Screen name="BlockTreeStack" component={BlockTreeStack} options={{
          ..._tabScreenOptions,
          tabBarItemStyle: {
            ...tabIconStyle,
            right: 0,
            marginRight: tabIconMargin + tabIconSeparator + tabIconSize,
        }}} />
        <Tab.Screen name="ChatStack" component={ChatStack} options={{
          ..._tabScreenOptions,
          tabBarItemStyle: {
            ...tabIconStyle,
            right: 0,
            marginRight: tabIconMargin,
        }}} />

      </Tab.Navigator>
      <React.Fragment>
        <TimeText theme={theme}></TimeText>
        <ProfileText theme={theme}></ProfileText>
      </React.Fragment>
    </NavigationContainer>
    </GestureHandlerRootView>
  );
}

export default App;

function TimeText({theme}: {theme: any}) {
  var [dd, setDate] = useState(new Date());
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000 );
    return function cleanup() {
        clearInterval(timer)
    }
  });

  const txt = [
      dd.getUTCHours().toString().padStart(2, '0'),
      dd.getUTCMinutes().toString().padStart(2, '0'),
      dd.getUTCSeconds().toString().padStart(2, '0'),
  ].join(':') + ' UTC'

  const fontSize = 12;
  const specialContainer: any = {
    position: 'absolute',
    bottom: 12,
    // width: '100%',
    left: '50%',
    marginLeft: -((txt.length || 0) * fontSize / 1.7) / 2,
    index: -10000,
  }

  const specialTextStyle: any = {
    color: 'white', // theme.colors.text,
    fontSize,
    textAlign: 'center',
    opacity: 0.6,
    ...styles.codeStyle,
  }

  const iconContainer: any = {
    position: 'absolute',
    bottom: 0,
    left: 300,
    // width: '100%',
    // index: -10000,
  }

  return (
    <View style={specialContainer}>
      <View style={iconContainer}>
        {/* <ProviderIcons chainIds={[1, 14, 19, 9001]} ></ProviderIcons> */}
      </View>
      <Text style={specialTextStyle}>{txt}</Text>
    </View>
  )
}

function ProfileText({theme}: {theme: any}) {
  const [profileName, setProfileName] = useState<string>();
  const actions = useActions();

  const initData = async (actions: any) => {
    if (!actions || !actions.wallet) {return;}
    const index = await actions.wallet.getCurrentProfileIndex();
    if (index === null) {return;}
    const profile: Profile = await actions.wallet.getProfile(index);
    if (!profile) {return;}
    setProfileName(profile.name.name);
  }

  useEffect(() => {
      async function init() {
        return initData(actions);
      }

      actions?.events?.plugems.wallet.onProfileCurrentIndex(() => {
        return initData(actions);
      });

      actions?.events?.plugems.wallet.onProfiles(() => {
        return initData(actions);
      });

      init();
  }, [actions]);

  const txt = profileName ? profileName.toLowerCase() : '';

  const fontSize = 12;
  const specialContainer: any = {
    position: 'absolute',
    bottom: -3,
    // width: '100%',
    left: '50%',
    marginLeft: -((txt.length || 0) * fontSize / 2.5) / 2,
    index: -10000,
  }

  const specialTextStyle: any = {
    color: 'white', // theme.colors.text,
    fontSize,
    textAlign: 'center',
    // verticalAlign: 'center',
    opacity: 0.6,
    fontFamily: 'RobotoCondensed-Light',
  }

  return (
    <View style={specialContainer}>
      <Text style={specialTextStyle}>{txt}</Text>
    </View>
  )
}

// function ProviderIcons(props: any) {
//   const {chainIds = []} = props;
//   const [urls, setUrls] = useState<(string | null)[]>([]);
//   const size = 40;

//   useEffect(() => {
//     async function getIcons() {
//       const icons: (string | null)[] = [];
//       for (let chainId of chainIds) {
//         const url = await getChainIconUrl(chainId);
//         icons.push(url);
//       }
//       setUrls(icons);
//     }
//     getIcons();
//   }, [chainIds]);

//   const icons = urls.map((uri: string | null, i: number) => {
//     if (uri) {
//       return <Image key={i} source={{uri}} style={{width: size, height: size, backgroundColor: 'white', marginLeft: i * size}} />
//     }
//     else {
//       const chain = getChain(chainIds[i]);
//       const name = chain?.shortName || chain?.name || chain?.chainId;
//       return <Text key={i} style={{color: 'white'}}>{name}</Text>;
//     }
//   });

//   const containerStyle: any = {
//     flex: 1,
//     display: 'flex',
//     textAlign: 'center',

//     // position: 'absolute',
//     // bottom: 0,
//     // left: 300,
//     // width: '100%',
//     // index: -10000,
//   }

//   return (
//     <View style={containerStyle}>
//       {icons}
//     </View>
//   )
// }
