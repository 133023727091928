import { Model } from '@nozbe/watermelondb';
import { field, text, date } from '@nozbe/watermelondb/decorators';

// @ts-ignore
export class ChatGroupIdentityModel extends Model {
  static table = 'chatgroups'
  static associations = {
    messages: { type: 'has_many', foreignKey: 'groupid' },
  }

  // @ts-ignore
  @field('id') id: string
  @field('blockheight') blockHeight: any
  @field('identity') identityAddress: any
  @field('participants') participants: any
  @date('createdat') createdAt: any

  getParticipants() {
    return JSON.parse(this.participants);
  }

}

export class ChatMessageModel extends Model {
  static table = 'messages'
  static associations = {
  }

  // @ts-ignore
  @field('id') id
  @field('hash') hash: any
  @field('groupid') groupId: any
  @field('blockheight') blockHeight: any
  @field('latlng') latlng: any
  @text('text') text: any
  @date('createdat') createdAt: any
  @field('userid') userId: any
  @field('data') data: any
  @field('image') image: any
  @field('video') video: any
  @field('audio') audio: any
  @field('system') system: any
  @field('sent') sent: any
  @field('received') received: any
  @field('pending') pending: any
}
