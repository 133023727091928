const folderToExtensions: any = {
    files: ['pdf', 'txt', 'json'],
    audio: ['mp3'],
    video: ['mp4', 'mov'],
    photo: ['jpg', 'jpeg', 'png', 'gif', 'heic', 'webp', 'bmp'],
};

const extensionToFolder: any = {};
Object.keys(folderToExtensions).map((key: string) => {
    folderToExtensions[key].map((ext: string) => {
        extensionToFolder[ext] = key;
    });
});

export {
    folderToExtensions,
    extensionToFolder,
}
