import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';
import schema from './schema';

export function getAdapter() {
    return new LokiJSAdapter({
        schema,
        // migrations,
        useWebWorker: false,
        useIncrementalIndexedDB: true,
        dbName: 'ganas',

        onQuotaExceededError: (error) => {
            console.error(error);
        },
        onSetUpError: (error) => {
            console.error(error);
        },
        extraIncrementalIDBOptions: {
            onDidOverwrite: () => {
                // Called when this adapter is forced to overwrite contents of IndexedDB.
                // This happens if there's another open tab of the same app that's making changes.
                // Try to synchronize the app now, and if user is offline, alert them that if they close this
                // tab, some data may be lost
                console.error('onDidOverwrite');
            },
            onversionchange: () => {
                // database was deleted in another browser tab (user logged out), so we must make sure we delete
                // it in this tab as well - usually best to just refresh the page
                // if (checkIfUserIsLoggedIn()) {
                //     window.location.reload()
                // }
            },
        },
    })
}
