import { Q } from '@nozbe/watermelondb';
import {BlockData} from './types';
import getDatabase from '../database/index';

const database = getDatabase();
const blocktree = database.get('blocktree');

export function getBlockTreeCollection() {
    return database.get('blocktree');
}

export async function getBlocksByParentId(parentId: string) {
  return blocktree.query(Q.where('parent_id', parentId)).fetch();
}

export async function setBlock(block: BlockData) {
    const _block = await blocktree.create(value => {
        // @ts-ignore
        value.label = block.label;
        // @ts-ignore
        value.parentId = block.parentId;
        // @ts-ignore
        value.predecessorId = block.predecessorId;
        // @ts-ignore
        value.children = JSON.stringify(block.children);
        // @ts-ignore
        value.lastChildId = block.lastChildId;
        // @ts-ignore
        value.hash = block.hash;
        // @ts-ignore
        value.height = block.height;
        // @ts-ignore
        value.type = block.type;
        // @ts-ignore
        value.contentId = block.contentId;
    });
    return _block;
}
