import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import PageWrap from '../components/PageWrap';
import StyledText from '../components/StyledText';

function AboutPage() {
  const { colors } = useTheme();
  let textStyles = { ...styles.text, color: colors.text };

  return (
    <PageWrap>
      <ScrollView style={styles.scrollView}>
        <View style={styles.containerText}>
          <StyledText style={textStyles}>ganas</StyledText>
          <StyledText style={textStyles}>In the history of humans there was no place where those who made the effort were recognized to the full extend of that effort (as quality and quantity). The great majority of recognition was misappropriated or shunned.</StyledText>
          <StyledText style={textStyles}>Just the same with intellectual property: in order to patent or reach market with something one has to pay and subject one's efforts to the abuse of institutions that are dependent upon those who want to maintain a status quo.</StyledText>

          <StyledText style={textStyles}>Mythos is the space that will solve those 2 problems.</StyledText>

          <StyledText style={textStyles}>There used to be a mythical place where heroes and inventors were universally recognized (across time and space) for the deeds they performed. Now that mythical space can become real.</StyledText>

          <StyledText style={textStyles}>This is the Mythos blockchain.</StyledText>

          <StyledText style={textStyles}>Ganas is the identity gateway on Mythos.</StyledText>
        </View>
      </ScrollView>
    </PageWrap>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginHorizontal: 20,
    padding: 5,
  },
  containerText: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 20,
  },
  text: {
    marginBottom: 20,
  },
});

export default AboutPage;
