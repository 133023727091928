import {quasarhash, addchainhash, cosmosabihash, cosmosgovhash, simpleStorageHash, quasarReverseHash, web3SheetsHash, evmDappHash, cwDappHash} from '../marks/marks';

const menuJson: any = [
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "add custom chain",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${addchainhash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "cosmos chains operations",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${cosmosabihash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "cosmos chains governance",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${cosmosgovhash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "EVM dApp from ABI",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${evmDappHash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "CosmWasm dApp from JSON Schema",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${cwDappHash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "quasar - Cosmos in EVM",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${quasarhash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "quasar - EVM tx sent by Cosmos",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${quasarReverseHash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "simple storage mark (goerli)",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": `https://mark.provable.dev/?ipfs=${simpleStorageHash}&m=p`
            }]}
        }
    },
    {
        "type": "Item",
        "props": {
            "hasNavArrow": false,
            "title": "uniswap",
            "onPress": {"type": "function", "subtype": "navigation.navigate", "args": ["BrowserPage", {
                "url": "https://app.uniswap.org/"
            }]}
        }
    },
];

export default menuJson;
