import { appSchema, tableSchema } from '@nozbe/watermelondb';

const chatGroupSchema: any = {
  name: 'chatgroups',
  columns: [
    // { name: 'id', type: 'string', isIndexed: true }, // hash
    { name: 'hash', type: 'string', isIndexed: true }, // data root
    { name: 'blockheight', type: 'number', isIndexed: true }, // height
    { name: 'identity', type: 'string', isIndexed: true },
    { name: 'participants', type: 'string' },
    { name: 'createdat', type: 'number', isIndexed: true },
  ],
};

const messageSchema: any = {
  name: 'messages',
  columns: [
    // id is given by gifted chat
    { name: 'hash', type: 'string', isIndexed: true }, // data root
    { name: 'groupid', type: 'string', isIndexed: true }, // chatGroup id
    { name: 'blockheight', type: 'number', isIndexed: true },
    { name: 'latlng', type: 'string', isIndexed: true }, // space ; interpolated
    { name: 'text', type: 'string'},
    { name: 'createdat', type: 'number', isIndexed: true }, // ms
    { name: 'userid', type: 'string', isIndexed: true }, // address
    { name: 'data', type: 'string', isOptional: true },
    { name: 'image', type: 'string', isOptional: true },
    { name: 'video', type: 'string', isOptional: true },
    { name: 'audio', type: 'string', isOptional: true },
    { name: 'system', type: 'string', isOptional: true },
    { name: 'sent', type: 'string', isOptional: true },
    { name: 'received', type: 'string', isOptional: true },
    { name: 'pending', type: 'string', isOptional: true },
  ],
};

const blocktreeSchema: any = {
  name: 'blocktree',
  columns: [
    // autogenerated id
    { name: 'label', type: 'string', isIndexed: true },
    { name: 'parent_id', type: 'string', isIndexed: true },
    { name: 'predecessor_id', type: 'string', isIndexed: true },
    { name: 'children', type: 'string'},
    { name: 'last_child_id', type: 'string'},
    { name: 'hash', type: 'string', isIndexed: true },
    { name: 'height', type: 'number', isIndexed: true },
    { name: 'type', type: 'string', isIndexed: true, isOptional: true },
    { name: 'content_id', type: 'string', isIndexed: true, isOptional: true },
  ],
};

const mySchema = appSchema({
  version: 1,
  tables: [
    tableSchema(chatGroupSchema),
    tableSchema(messageSchema),

    tableSchema(blocktreeSchema),
  ],
});

export default mySchema;
