import {ethers} from 'ethers';
import { chains as ethchains } from 'eth-chains';
import {Chains} from 'eth-chains/dist/src/types';
import * as storage from '../storage';
import * as web3signer from '../wallet/accounts';
import {randomElement} from '../../utils/blockchain';

const INFURA_KEY = '31bd08cad6fa436faf530deb80db2d66';

const allEvmChains = ethchains.all();

interface NamespaceMap {
    eip155: Chains;
    cosmos: any;
}

const namespaceMap: NamespaceMap = {
    eip155: allEvmChains,
    cosmos: {},
};

const providerMap: any = {
    eip155: {},
    cosmos: {},
};

const DEFAULT_CHAINS = {
    eip155: [
        '5',
    ],
    cosmos: [],
};

export async function getProvider(chainId?: string, force = false) {
    let namespace = 'eip155';
    let id;
    if (chainId) {
        ([namespace, id] = chainId.split(':'));
    }
    else {
        const supportedProviders = Object.keys(providerMap[namespace]);
        if (supportedProviders.length < 1) {throw new Error('No providers are set.');}
        id = supportedProviders[0];
    }
    if (!force && providerMap[namespace][id]) {return providerMap[namespace][id];}

    if (namespace !== 'eip155') {throw new Error(`Namespace not supported: ${namespace}`);}

    const chain = namespaceMap[namespace][parseInt(id, 10)];
    if (!chain) {throw new Error(`Chain is not supported: ${id}`);}
    if (chain.rpc.length === 0) {throw new Error(`No chain rpc endpoint: ${id}`);}
    let rpcUrl = chain.rpc.find((url: string) => url.includes('infura'));
    let [elem1, _] = randomElement(chain.rpc.filter((v: any) => v.includes('https')));
    rpcUrl = elem1;
    // @ts-ignore
    rpcUrl = rpcUrl.replace('${INFURA_API_KEY}', INFURA_KEY);
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    providerMap[namespace][id] = provider;
    return provider;
}

// provider can be null for initial web dApp requests
export async function jsonrpcRequest ({message = {}}: any, profileIndex: number, provider: any = null) {
    if (!provider && typeof profileIndex === 'number') {
        const profile = await web3signer.getProfile(profileIndex);
        const monochain = profile.chains[profile.chainType].monochain;
        provider = await getProvider(monochain);
    }

    const payloadMessage: any = {
        jsonrpc: message.jsonrpc || '2.0',
        id: message.id,
        method: message.method,
    };

    switch (message.method) {
    case 'eth_accounts':
        // TODO don't give accounts if user did not confirm connection
        // TODO user UI choose profile
        let result = await web3signer.getAccounts(0).catch(console.log);
        payloadMessage.result = result;
        break;
    case 'eth_requestAccounts':
        // TODO notify user
        payloadMessage.result = await web3signer.getAccounts(0).catch((e: any) => ({error: e}));
        break;
    case 'wallet_addEthereumChain':
        console.log('wallet_addEthereumChain', message);
        if (!message.params || message.params.length === 0) {
            payloadMessage.result = {error: "Invalid request"};
            break;
        }
        const chainData = message.params[0];
        // TODO better data validation
        if (typeof chainData !== 'object' || !chainData.chainId || !chainData.rpc) {
            payloadMessage.result = {error: "Invalid chain data"};
            break;
        }
        const ns: 'eip155' | 'cosmos' = chainData.namespace || 'eip155';
        namespaceMap[ns][parseInt(chainData.chainId, 10)] = chainData;
        const custom = await getChainCustomData();
        custom[ns][parseInt(chainData.chainId, 10)] = chainData;
        await setChainCustomData(custom);
        payloadMessage.result = {success: true};
        break;
    case 'wallet_switchEthereumChain':
        console.log('wallet_switchEthereumChain', message);
        if (!message.params || message.params.length === 0) {
            payloadMessage.result = {error: "Invalid request"};
            break;
        }
        const arg = message.params[0];
        if (!arg || !arg.chainId) {
            payloadMessage.result = {error: "ChainId missing"};
            break;
        }
        const chainId = parseInt(arg.chainId, 10)
        const namespace: 'eip155' | 'cosmos' = arg.namespace || 'eip155';
        console.log('---switch chain:', chainId, namespace);
        const chain = namespaceMap[namespace][chainId]
        if (!chain) {
            payloadMessage.result = {error: "Invalid chain"};
            break;
        }

        // @ts-ignore
        let dappType = chain.network_type;
        if (!dappType) dappType = getNetworkType(chain);

        const profile = await web3signer.getCurrentProfile();
        // we allow new chains
        if (!profile.chains[dappType][namespace].includes(chainId.toString())) {
            profile.chains[dappType][namespace].push(chainId.toString());
        }

        const monochain = namespace + ':' + chainId.toString();

        if (dappType === profile.chainType) {
            profile.chains[dappType].monochain = monochain;
            web3signer.setCurrentProfile({index: profile.index, chainType: profile.chainType, chains: profile.chains[dappType]});
            payloadMessage.result = {success: true};
        }
        else {
            // payloadMessage.result = {error: `Cannot change from ${profile.chainType} to ${dappType}`};
            // TODO not lose the currect profile
            const newchains: any = {eip155: [], cosmos: [], monochain};
            newchains[namespace] = [chainId.toString()];
            web3signer.setCurrentProfile({index: profile.index, chainType: dappType, chains: newchains});
            payloadMessage.result = {success: true};
        }
        break;
    case 'eth_signTransaction':
        // TODO account with which to sign
        try {
            const serializedTx = await web3signer.signTransaction(provider, message.params, profileIndex);
            payloadMessage.result = serializedTx;
        } catch (e: any) {
            payloadMessage.result = {error: e.message};
        }
        break;
    case 'eth_sendTransaction':
        let receipt;
        try {
            receipt = await web3signer.sendTransaction(provider, message.params[0], profileIndex);
            // only send the hash for the injected provider
            payloadMessage.result = receipt.hash;
        } catch (e: any) {
            console.error(e);
            payloadMessage.result = {error: e.message};
        }
        break;
    default:
        payloadMessage.result = await provider.send(message.method, message.params).catch((e: Error) => ({error: e}));
    }
    return payloadMessage;
}

async function initChainData() {
    const custom = await getChainCustomData();
    for (let chainId in custom.eip155) {
        namespaceMap.eip155[parseInt(chainId, 10)] = custom.eip155[chainId];
    }
}

async function initProviders() {
    providerMap.eip155 = {};
    const profile = await web3signer.getCurrentProfile();
    if (!profile) return;
    // migration
    if (!profile.chains[profile.chainType] && profile.chains.eip155) {
        // @ts-ignore
        profile.chains[profile.chainType] = profile.chains;
        profile.chains[profile.chainType].monochain = 'eip155:' + profile.chains[profile.chainType].eip155[0];
    }
    const chains = profile.chains[profile.chainType];
    // TODO cosmos too
    chains.eip155.map((chainId: string) => {
        providerMap.eip155[chainId] = getProvider('eip155:' + chainId, true);
    });
}

async function getChainCustomData() {
    const namespaces = await storage.getDataObject('web3provider.customchains');
    return namespaces || {eip155: {}, cosmos: {}};
}

function setChainCustomData(namespaces: any) {
    // TODO check correctness of data
    return storage.storeData('web3provider.customchains', JSON.stringify(namespaces));
}

export async function getChainIconUrl(chainId: string) {
    const chain = ethchains.getById(parseInt(chainId, 10));
    if (!chain) {return null;}
    if (!chain.icon) {return null;}

    const iconData: any = await fetch(`https://raw.githubusercontent.com/ethereum-lists/chains/master/_data/icons/${chain.icon}.json`);
    const iconObj = await iconData.json();
    const ipfsHash = iconObj[0].url.slice(7);
    const iconUrl = `https://github.com/ethereum-lists/chains/raw/master/_data/iconsDownload/${ipfsHash}`;

    return iconUrl;
}

export function getChain(chainId: string) {
    return ethchains.getById(parseInt(chainId, 10));
}

async function getCustomNamespaces() {
    const data = await getChainCustomData();
    const namespace: any = {eip155: {mainnet: {}, testnet: {}}, cosmos: {mainnet: {}, testnet: {}}};
    for (let chainId in data.eip155) {
        const chain = data.eip155[chainId];
        const networkType = getNetworkType(chain);
        namespace.eip155[networkType][chainId.toString()] = chain;
    }
    for (let chainId in data.cosmos) {
        const chain = data.cosmos[chainId];
        const networkType = getNetworkType(chain);
        namespace.cosmos[networkType][chainId.toString()] = chain;
    }
    return namespace;
}

function getNetworkType(chainData: any) {
    return JSON.stringify(chainData).toLowerCase().includes('testnet') ? 'testnet' : 'mainnet';
}

export async function extension () {
    const extensions = {
        label: 'web3provider',
        items: [
            {
                label: 'request',
                value: jsonrpcRequest,
            },
            {
                label: 'getCustomNamespaces',
                value: getCustomNamespaces,
            },
            {
                label: 'getProvider',
                value: getProvider,
            },
            // {
            //     label: 'setProviders',
            //     value: setProviders,
            // },
        ],
    };

    // init custom chains needs to be before providers
    await initChainData();
    await initProviders();

    return {extensions};
}
