import * as React from 'react';
import {StyleSheet, View, TextInput, TouchableOpacity, ScrollView} from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import type { Routes } from '../../views/Routes';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import QRCode from 'react-qr-code';
import commonStyles from '../../styles';
import {chatInstance} from '../../services/chat/chat';
import {generateName} from '@ark-us/name';
import StyledText from '../StyledText';
import ClipboardCopyButton from '../ClipboardCopy';
import TextButton from '../TextButton';
import {GanasThemeColors} from '../../theme/types';

type Props = NativeStackScreenProps<Routes, 'NewContact'>;
export default function NewContact({ navigation, route }: Props): React.ReactElement {
  const ownAddress = route.params.id;
  const initContactId = route.params.contactId;
  let colors: GanasThemeColors;
  // @ts-ignore
  ({colors} = useTheme());

  const [contactId, setContactId] = React.useState<string>(initContactId || '');

  React.useEffect(() => {
    if (!initContactId) {return;}
    setContactId(initContactId);
  }, [initContactId]);

  const onContactAdd = React.useCallback(async () => {
    if (!contactId) {return;}
    await chatInstance.addContact({id: contactId, name: generateName(contactId)});
    navigation.navigate('ContactsPage');
  }, [contactId, navigation]);

  const onScan = () => {
    const callb = (url: string) => {
      return ['NewContact', {id: ownAddress, contactId: url}];
    };
    navigation.navigate('QrcodeScannerPage', {action: callb});
  };

  return (
    <ScrollView style={{display: 'flex', marginTop: 1, marginBottom: 30}}>
      <View style={{width: '100%', flexDirection: 'row'}}>
        <View style={{flex: 6, height: 60}}>
          <TextInput
            style={{
              ...commonStyles.textStyle,
              ...styles.textInput,
              borderColor: colors.border,
              color: colors.text,
            }}
            value={contactId}
            placeholder="contact id ..."
            placeholderTextColor={colors.placeholder}
            onChangeText={setContactId}
           />
        </View>
        {!(!contactId) && (
          <TouchableOpacity style={styles.addButton} onPress={onContactAdd}>
            <IonIcon name="add" color={colors.text} size={24} />
          </TouchableOpacity>
        )}
      </View>
      <View style={{marginTop: 20}}>
        <TextButton
          styleButton={{borderColor: colors.primary}}
          styleText={{color: colors.primary}}
          title="scan contact"
          onPress={onScan}
         />
      </View>

      <View style={{display: 'flex', flexDirection: 'row', marginTop: 40, marginBottom: 10}}>
          <StyledText style={{fontSize: 28}}>Share your identity:</StyledText>
      </View>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <ClipboardCopyButton value={ownAddress} />
          <StyledText style={{fontSize: 14, textAlign: 'center'}}>{ownAddress}</StyledText>
      </View>
      <View style={{ height: 'auto', margin: 0, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#ddd', padding: 20 }}>
          <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '400px', width: '100%' }}
              value={ownAddress}
              // @ts-ignore
              viewBox={'0 0 256 256'}
          />
        </View>
    </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  textInput: {
    borderRadius: 5,
    flex: 1,
    borderWidth: 1,
    marginRight: 8,
    paddingLeft: 8,
    textAlign: 'center',
  },
  addButton: {
    ...commonStyles.iconButton,
    flex: 1,
  },
  addButton2: {
    ...commonStyles.iconButton,
  },
  navigateButton: {
    ...commonStyles.iconButton,
    float: 'right',
    marginTop: 20,
  },
});
