import * as React from 'react';

const menuJson: any = [
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'about',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['AboutPage']},
        },
    },
    {
        type: 'Item',
        props: {
            hasNavArrow: true,
            title: 'permissions',
            onPress: {type: 'function', subtype: 'navigation.navigate', args: ['PermissionsPage']},
        },
    },
];

export default menuJson;
