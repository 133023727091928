import React, {useCallback, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  Button,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import PageWrap from '../components/PageWrap';
import {toggleTorch} from '../services/torch';

function TorchPage() {
  const { colors } = useTheme();
  let textStyles = { color: colors.text };

  let [state, setState] = useState<boolean>(false);

  const toggle = useCallback(() => {
    const newstate = !state;
    toggleTorch(newstate);
    setState(newstate);
  }, [state]);

  return (
    <PageWrap>
      <ScrollView style={styles.scrollView}>
        <Button title="TORCH" onPress={toggle} />
      </ScrollView>
    </PageWrap>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 20,
    padding: 5,
  },
});

export default TorchPage;
