import React from 'react';

const chainsWs = [
  'wss://mythos-rpc-wss.provable.dev:443',
  'wss://tendermint.bd.evmos.org:26657/websocket',
  'wss://rpc.uni.juno.deuslabs.fi/websocket',
  'wss://rpc.kava.io/websocket',
  'wss://akash-sentry01.skynetvalidators.com:26657/websocket',
  'wss://rpc-iris.keplr.app/websocket',
  'wss://rpc-cronos.crypto.org/websocket',
  'wss://rpc-cosmoshub.blockapsis.com/websocket',
];

const chainsRpc = [
  // "https://mythos-rpc.provable.dev",
  'https://tendermint.bd.evmos.org:26657',
  'https://rpc.uni.juno.deuslabs.fi',
  'https://rpc.kava.io',
  'https://rpc-iris.keplr.app',
  'https://rpc-cronos.crypto.org',
  'https://rpc-cosmoshub.blockapsis.com',
];

function chainSubscription(callback: Function) {
  let stri = '';
  chainsWs.map((url) => {
    let socket = new WebSocket(url);
    socket.onopen = function () {
      socket.send(
        JSON.stringify({
          jsonrpc: '2.0',
          method: 'subscribe',
          params: ["tm.event='NewBlockHeader'"],
          id: 1,
        }),
      );
    };

    socket.onmessage = function (event) {
      let dat = JSON.parse(event.data);
      let temp = dat.result.data.value.header;
      stri =
        temp.chain_id +
        '#' +
        ('000000' + temp.height).slice(-10) +
        ('||||||' + temp.time).slice(-32).replace(/\|/g, ' ');
    };

    socket.onclose = function (event) {
      console.log('socket onclose');
    };

    socket.onerror = function (error) {
      //alert(`[error] ${error.message}`);
    };
  });
}

export function randomElement(arr: any) {
  const index = Math.round(Math.random() * (arr.length - 1));
  return [arr[index], arr.slice(0, index).concat(arr.slice(index + 1))];
}

async function getBlockHashes(): Promise<string[]> {
  let hashes = [];
  let [elem1, newChainsRpc] = randomElement(chainsRpc);
  let [elem2, _] = randomElement(newChainsRpc);
  const randomChains = [elem1, elem2];

  for (let url of randomChains) {
    let data = await fetch(url + '/block').catch((e) => {
      console.error(e.message);
      return null;
    });
    if (!data) {
      continue;
    }
    let content = await data.json();
    let header = content.result.block.header;
    hashes.push(
      header.chain_id +
        '#' +
        header.height.padStart(9, '0') +
        header.time.padStart(32, '|').replace(/\|/g, ' '),
    );
    hashes.push(content.result.block_id.hash || '<block_id>');
  }
  return hashes;
}

export {getBlockHashes};
