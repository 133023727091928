import React from 'react';
import {
  StyleSheet,
} from 'react-native';
import PageWrap from '../components/PageWrap';
import {Permissions} from '../components/Permissions';

function PermissionsPage() {
  return (
    <PageWrap style={{...styles.containerText}}>
        <Permissions textStyle={styles.text} />
    </PageWrap>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerText: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 50,
    paddingLeft: 30,
  },
  text: {

  },
});

export default PermissionsPage;
