export async function toggleTorch() {
    console.error('Not implemented.');
}

export async function extension () {
    const extensions = {
        label: 'utils',
        items: [
            {
                label: 'torch-toggle',
                value: toggleTorch,
            },
        ],
    };

    return {extensions};
}
